export const getWeeksInMonth = (month: number, year: number) => {
  const weeks: Array<{ start: number; end: number; dates: number[] }> = [];
  const lastDate = new Date(year, month + 1, 0);
  const numDays = lastDate.getDate();

  for (let i = 1; i <= numDays; i += 7) {
    const start = i;
    const end = Math.min(i + 6, numDays);
    const dates = Array.from({ length: end - start + 1 }, (_, idx) => start + idx);

    weeks.push({ start, end, dates });
  }

  return weeks;
};
