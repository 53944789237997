import React, { useCallback, useMemo, MouseEvent } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'services/translation';
import { useBreadcrumbs, useFilerParamsId } from 'hooks';
import { MarketplaceFraud } from 'interfaces/business';
import { RowsType } from 'utils/tables';
import CustomModal from '../../../../../services/customFormDialog';
import { MenuActions, MenuItemComponentType } from '../../../../../components/MenuActions';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import { ButtonDetailsType } from '../../../../../types/Button';
import { TitleBreadcrumbs } from '../../../../../interfaces';
import { useMarketplaceFraudLibbyFetch } from '../../../../../business/marketplace';
import { LibbyObject } from 'types';
import { MakeCell } from 'routes/components/MakeCell';
import SettingsIcon from '@material-ui/icons/Settings';
import { AnyObject } from 'types';
import { FraudDialogComponent } from '../components/FraudSettingsForm/FraudDialogComponent';

const FraudDialogSettings = CustomModal(FraudDialogComponent);

const ConfirmModal = CustomModal(ConfirmDialog);
export const useFraudAdministratorLogic = ({ libby }: { libby: LibbyObject }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { orderBy, direction, paramsFetch, handleRequestSort, setFilter, filter } = useFilerParamsId({
    orderInit: 'marketplace.name',
    daoName: 'ster_marketplace_fraud'
  });

  const { data: allData, fetchMore, working, updateData } = useMarketplaceFraudLibbyFetch(paramsFetch);

  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();

  const title = useBreadcrumbs(t('Settings Chargeback Marketplaces'));
  const titleShow = useBreadcrumbs(t('Settings Chargeback Marketplaces'), state && state?.goBack?.length ? state.goBack : title);

  const handleOpenMarketplaceSettings = useCallback(
    async (e: AnyObject, marketplace_fraud: MarketplaceFraud) => {
      e.stopPropagation();
      try {
        const updatedSettings = await FraudDialogSettings.show({
          title: 'Fraud Validation Settings',
          properties: ['documentNumber', 'type.name'],
          id: 'marketplace_fraud_id',
          label: '',
          marketplace_fraud
        });
        if (updatedSettings) {
          const marketplaceFraudUpdated = await libby.ster_marketplace_fraud.save({ ...marketplace_fraud, fraud_config: updatedSettings });
          updateData(marketplaceFraudUpdated, 'marketplace_fraud_id');
          enqueueSnackbar(t('The settings have been successfully saved for $$$$').replace('$$$$', marketplace_fraud.marketplace.name), { variant: 'success' });
        }
      } catch (error: any) {
        // nothing
      }
    },
    [libby, t, updateData, enqueueSnackbar]
  );

  const handleOpenSyncMarketplace = useCallback(
    async (marketplace_chargeback: MarketplaceFraud) => {
      try {
        const validation = await ConfirmModal.show({
          title: t(`${marketplace_chargeback?.active ? 'Disable' : 'Enable'} Marketplace`),
          content: t(`Are you sure that you want to ${marketplace_chargeback?.active ? 'disable' : 'enable'} this marketplace?`),
          confirmText: t('Yes'),
          cancelText: t('No')
        });
        if (validation) {
          const updateResponse = await libby.ster_marketplace_fraud.save({ ...marketplace_chargeback, active: !marketplace_chargeback.active });
          updateData(updateResponse, 'marketplace_fraud_id');
          enqueueSnackbar(t(`Marketplace ${marketplace_chargeback?.active ? 'disabled' : 'enabled'} successfully`), { variant: 'success' });
        }
      } catch (err) {
        console.log(err, 'err');
        if (err !== false) {
          enqueueSnackbar(t(`There was a problem while trying to ${marketplace_chargeback?.active ? 'disable' : 'enable'} this marketplace`), { variant: 'error' });
        }
      }
    },
    [libby, t, enqueueSnackbar, updateData]
  );

  const handleOpenGenericRetMarketplace = useCallback(
    async (marketplace_chargeback: MarketplaceFraud) => {
      try {
        const validation = await ConfirmModal.show({
          title: t(`${marketplace_chargeback?.generic_retention ? 'Disable' : 'Enable'} Generic retention`),
          content: `${t('Do you want to configure generic retention for the market $$$$?').replace('$$$$', marketplace_chargeback.marketplace.name)}`,
          confirmText: t('Yes'),
          cancelText: t('No')
        });
        if (validation) {
          const updateResponse = await libby.ster_marketplace_fraud.save({
            ...marketplace_chargeback,
            generic_retention: !marketplace_chargeback.generic_retention
          });
          updateData(updateResponse, 'marketplace_fraud_id');
          enqueueSnackbar(t(`Generic retention ${marketplace_chargeback?.generic_retention ? 'disabled' : 'enabled'} successfully`), { variant: 'success' });
        }
      } catch (err) {
        console.log(err, 'err');
        if (err !== false) {
          enqueueSnackbar(t(`There was a problem while trying to ${marketplace_chargeback?.generic_retention ? 'disable' : 'enable'} this marketplace`), { variant: 'error' });
        }
      }
    },
    [libby, t, enqueueSnackbar, updateData]
  );

  const menuArray = useCallback(
    (marketplace_chargeback: MarketplaceFraud): MenuItemComponentType[] => {
      const menu: MenuItemComponentType[] = [
        {
          title: marketplace_chargeback.active ? t('Disable') : t('Enable'),
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            handleOpenSyncMarketplace(marketplace_chargeback);
          },
          disabled: false,
          color: marketplace_chargeback.active ? 'error' : 'inherit'
        },
        {
          title: 'Generic retention',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            handleOpenGenericRetMarketplace(marketplace_chargeback);
          },
          disabled: false,
          color: marketplace_chargeback.generic_retention ? 'error' : 'inherit'
        }
      ];
      return menu.filter((menuItems) => menuItems.disabled === false);
    },
    [handleOpenSyncMarketplace, handleOpenGenericRetMarketplace, t]
  );
  const rows: RowsType[] = useMemo(() => {
    const dataOrder = allData as MarketplaceFraud[];
    return dataOrder.map((marketplace_fraud: MarketplaceFraud) => {
      const {
        marketplace_fraud_id,
        active,
        marketplace: { name: marketplace_name }
      } = marketplace_fraud;
      return {
        id: marketplace_fraud_id,
        ...marketplace_fraud,
        active,
        marketplace: marketplace_name,
        configuration: <MakeCell label={''} icon={SettingsIcon} onClick={(e) => handleOpenMarketplaceSettings(e, marketplace_fraud)} />,
        actions: <MenuActions menuArray={menuArray(marketplace_fraud)} />
      };
    });
  }, [allData, menuArray, handleOpenMarketplaceSettings]);

  const buttons: ButtonDetailsType[] = useMemo(() => [], []);

  return {
    working,
    direction,
    orderBy,
    handleRequestSort,
    setFilter,
    filter,
    rows,
    fetchMore,
    buttons,
    titleShow
  };
};
