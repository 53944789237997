import { Objective } from '../../../../../interfaces/business';
import { Column } from '../../../../components/InfoTable';

export const columnsReportingObjectSaleSetting: Array<Column> = [
  {
    id: 'actions',
    label: '',
    noSort: true,
    width: 30
  },
  {
    id: 'name',
    label: 'Name',
    style: {
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'month and year',
    label: 'Month & year',
    noSort: true,
    orderById: 'objective_year',
    render: ({ month_year }: Objective) => month_year
  },

  {
    id: 'total_quantity',
    label: 'Total quantity',
    noSort: true,
    orderById: 'objective_quantity',
    render: ({ total_quantity }: Objective) => total_quantity
  },
  {
    id: 'total_amount',
    label: 'Total amount',
    noSort: true,
    orderById: 'objective_amount',
    render: ({ total_amount }: Objective) => total_amount
  }
];
