import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';
import { OrderRetentionReason } from '../../../interfaces/business';

const DAO_NAME = 'ster_order_retention_reason';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<OrderRetentionReason>(DAO_NAME);

export const useOrderRetentionReasonFetchById = fetchById;
export const useOrderRetentionReasonLibbyCall = libbyCall;
export const useOrderRetentionReasonDAO = libbyDAO;
export const useOrderRetentionReasonLibbyFetch = libbyFetch;
