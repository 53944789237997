import { Grid } from '@material-ui/core';
import { ButtonComponent } from '../../../../components/Button';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { useTranslation } from '../../../../services/translation';
import InfoTable from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';
import { SearchFilters } from './components/SearchFilters';
import { useReportingGroupProductSaleSettingsAddLogic } from './hook/useReportingGroupProductSaleSettingsAdd';

export const ReportingGroupProductSaleSettingsAdd = () => {
  const { titleShow, columns, rowsProducts, working, direction, handleRequestSort, buttonDetails, filter, setFilter, orderBy, fetchMore } = useReportingGroupProductSaleSettingsAddLogic();
  const { t } = useTranslation();
  return (
    <>
      <ScreenAligned
        title={titleShow}
        additionalTitle={
          <>
            <Grid container>
              {buttonDetails.map((dataButton) => (
                <ButtonComponent
                  key={dataButton.id}
                  title={dataButton.title}
                  className={dataButton.className}
                  variant={dataButton.variant}
                  color={dataButton.color}
                  onClick={dataButton.onClick}
                  type={dataButton.type}
                  disabled={dataButton.disabled}
                  loading={dataButton.loading}
                />
              ))}
            </Grid>
          </>
        }
      >
        <SearchFilters onFilterChange={setFilter} filter={filter} />
        <div style={{ height: '500px', overflowY: 'auto' }}>
          <InfoTable columns={columns} rows={rowsProducts} onBottomScroll={fetchMore} onSortChange={handleRequestSort} direction={direction} rowIdKey="product_id" md={12} sm={12} xs={12} orderBy={orderBy} />
        </div>
        <LoadingData label={`${t('Loaded fields')}`} working={working} cant={rowsProducts?.length} />
      </ScreenAligned>
    </>
  );
};
