import { ObjectivePeriod } from '../../interfaces/business';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_objective_period';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<ObjectivePeriod>(DAO_NAME);

export const useObjectivePeriodsFetchById = fetchById;
export const useObjectivePeriodsLibbyCall = libbyCall;
export const useObjectivePeriodsDAO = libbyDAO;
export const useObjectivePeriodsLibbyFetch = libbyFetch;
