import { ProductsAvailable } from '../interfaces/business';
import { makeBusinessEntity } from '../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'products_available';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<ProductsAvailable>(DAO_NAME);

// hooks
export const useProductsAvailableFetchById = fetchById;
export const useProductsAvailableLibbyCall = libbyCall;
export const useProductsAvailableDAO = libbyDAO;
export const useProductsAvailableLibbyFetch = libbyFetch;
