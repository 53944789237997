import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class OrderRetentionReasonDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_retention_reason', 'order_retention_id');
  }

  getAll() {
    return this.query().limit(0, 20000).run();
  }
}
