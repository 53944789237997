import { formatDate, formatMoney, translate } from '../../../../utils';
import { paymentMethods, PaymentMethodType } from '../../../Budget/const';

export const basicInformationMap = [
  {
    key: 'updated_at',
    label: 'Last Update',
    formatter: (value: any) => formatDate(value, true, false)
  },
  {
    key: 'source',
    label: 'Marketplace',
    formatter: (value: any) => value?.marketplace?.name
  },
  {
    key: 'owner_id',
    label: 'Owner ID'
  },
  {
    key: 'state',
    label: 'State',
    formatter: (value: any) => translate(value?.name)
  },
  {
    key: 'order_cancel_reason',
    label: 'Reason for cancellation',
    formatter: (value: any) => {
      return value?.order_cancel_reason_id !== '-1' ? translate(value?.name) : '-';
    }
  },
  {
    key: 'order_retention_reason',
    label: 'Reason for retention',
    formatter: (value: any) => {
      return value ? value?.retention_reason.name : '-';
    }
  },
  {
    key: 'amount',
    label: 'Amount',
    formatter: (value: any) => formatMoney(value)
  },
  {
    key: 'tax',
    label: 'Tax',
    formatter: (value: any) => formatMoney(value)
  },
  {
    key: 'total',
    label: 'Total',
    formatter: (value: any) => formatMoney(value)
  },
  {
    key: 'shipment_cost',
    label: 'Shipment Cost',
    formatter: (value: any, data: any) => formatMoney(value || data?.shipping_cost)
  },
  {
    key: 'order_liquidation',
    label: 'Liquidation',
    formatter: (value: any) => (value ? value.reference : 'No liquidado'),
    link: (value: any) => {
      return value ? `/liquidations/detail/${value.order_liquidation_group_id}` : '';
    },
    enableNull: true
  }
];

export const basicInformationMapWithPaymentGateway = [
  ...basicInformationMap,
  {
    key: 'payment_gateway',
    label: 'GW of payments',
    enableNull: true,
    formatter: (value: any) => translate(value?.payment_gateway_id ? value.name : 'Does not have GW of payments')
  }
];

export const basicInformationMapWithPaymentMethod = [
  ...basicInformationMap,
  {
    key: 'payment_methods',
    label: 'Payment Method',
    enableNull: true,
    formatter: (value: any, data: any) => {
      const methods: PaymentMethodType[] = [];
      paymentMethods.map((item) => item.options.map((option) => methods.push(option)));

      return translate(methods.find((item) => +item.ID === data?.orderB2Bs[0]?.payment_method_id)?.['Plazos de pago'] || 'Does not have Payment Method');
    }
  },
  {
    key: 'expiration_date',
    label: 'Expiration date',
    enableNull: true,
    formatter: (value: any, data: any) => {
      const expirationDate = data?.orderB2Bs[0]?.expiration_date;
      return formatDate(expirationDate, true, false);
    }
  },
  {
    key: 'qty_deferred_payment',
    label: 'Quantity Deferred Payment',
    enableNull: true,
    formatter: (value: any, data: any) => {
      const qtyDeferredPayment = data?.orderB2Bs[0]?.qty_deferred_payment || 'Does not have quantity';
      return `${qtyDeferredPayment} ${translate('Days')}`;
    }
  },
  {
    key: 'qty_delivery_days',
    label: 'Quantity Delivery Days',
    enableNull: true,
    formatter: (value: any, data: any) => {
      const qtyDeliveryDays = data?.orderB2Bs[0]?.qty_delivery_days || 'Does not have quantity';
      return `${qtyDeliveryDays} ${translate('Days')}`;
    }
  },
  {
    key: 'operation_id',
    label: 'Operation',
    enableNull: true,
    formatter: (value: any, data: any) => {
      return data?.operation?.operation_id || 'No Operation';
    }
  }
];
