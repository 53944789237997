import { makeValidator, ValidatorRule, ValidatorType } from '../../../../../utils';

export const formReportingObjectivePeriodInitialValuesEdit = {
  quantity: '',
  amount: ''
};

const validations: ValidatorRule[] = [
  { key: 'quantity', validators: [ValidatorType.REQUIRED, ValidatorType.NUMBER, ValidatorType.POSITIVE] },
  { key: 'amount', validators: [ValidatorType.REQUIRED, ValidatorType.NUMBER, ValidatorType.POSITIVE] }
];

export const validateReportingObjectivePeriodEdit = makeValidator(validations);
