import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { orderBy } from 'lodash';
import moment from 'moment';
import { Box, Card, Grid, Switch, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useTranslation } from '../../../../services/translation';
import { format } from '../../../../util';
import { ComposedChartComponent } from '../../../../components/BarChart/ComposedChart';
import { useInterval } from '../../../../hooks/useIntervalPage';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { ButtonDetailsType } from '../../../../types/Button';
import InfoCollapsibleTable from '../../../components/InfoCollapsibleTable';
import { ButtonComponent } from '../../../../components/Button';
import { useReportingObjectiveSaleCategoryLogic } from './hook';
import { SearchFilters } from './components/SearchFilters';
import { FilterBarSelectionReportingObjectiveSales } from './type';
import { useBreadcrumbs } from '../../../../hooks';
import { useMenuAction } from '../../../../hooks/useMenuActions';
import { MENU, MENU_ACTIONS, ORDER_SOURCE_TYPE, GOAL_RANGE_TYPE } from '../../../../const';
import { columnsReportObjetiveSales } from '../../../../models/orders/columns';
import InfoTable from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';

const filterInit: FilterBarSelectionReportingObjectiveSales = {
  canal: [],
  category: [],
  sale_type: ORDER_SOURCE_TYPE.OFFLINE.toString(),
  goal_range_type: GOAL_RANGE_TYPE.WEEKLY.toString(),
  from: moment().startOf('month').format('YYYY-MM-DD'),
  to: moment().endOf('month').format('YYYY-MM-DD')
};

export const ReportingObjectiveSalesList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'));

  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  // Build the columns, depends on t function for translations

  // eslint-disable-next-line arrow-body-style

  // FIXME CHINO-SDK IS NOT TAKING THE ALIAS, REMOVE THIS WHEN IS OK

  const [filter, setFilter] = useState<FilterBarSelectionReportingObjectiveSales>(filterInit);
  const [activeFilterSales, setActiveFilterSales] = useState<boolean>(false);
  const [typePeriod, setTypePeriod] = useState<string>('month');

  const { validateMenuActions } = useMenuAction(MENU.REPORTING_OBJETIVE_OFFLINE_SALE);

  const {
    statisticsProductsCategory,
    dataFormatPayload,
    statisticsQuantityCategory,
    statisticsProductCanalWithTotal,
    recall,
    working: workingData,
    statisticsProductsMonetaryAllTotal,
    statisticsReportingObjectiveSaleUser,
    salesExecutions,
    weeksInformation,
    setFilterSales,
    DateFilter,
    DateFilterWorking
  } = useReportingObjectiveSaleCategoryLogic({ filterInit, filter });
  const [stateRefresh, setState] = React.useState(false);

  const delay = useMemo(() => (stateRefresh ? 60000 : null), [stateRefresh]);

  const refresh = useCallback(() => {
    recall?.(filter);
  }, [recall, filter]);

  const { time, resetTime } = useInterval(refresh, delay);

  const OnChangeApplyFilters = useCallback(() => {
    if (stateRefresh) {
      resetTime();
    } else {
      refresh();
      setFilterSales({ week: 0, day: '', category: '' });
      setActiveFilterSales(false);
    }
  }, [stateRefresh, resetTime, setFilterSales, refresh]);

  const title = useBreadcrumbs('Offline sales by goals');
  const buttons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'dispatchDetailAddOrder',
        onClick: OnChangeApplyFilters,
        title: `${t('Refresh')} ${stateRefresh ? `(${time} ${t('Seconds').toLowerCase()})` : ''}`,
        disabled: workingData,
        loading: stateRefresh ? workingData : false,
        show: true
      },
      {
        id: 'updatedAddOrder',
        onClick: () =>
          history.push({
            pathname: `${match.path}/setting`,
            state: {
              goBack: title
            }
          }),
        title: t('Settings'),
        variant: 'contained',
        show: validateMenuActions(MENU_ACTIONS.OFFLINE_OBJECTIVE_REPORTING_BUTTON.toString())
      }
    ],
    [time, OnChangeApplyFilters, workingData, stateRefresh, t, match.path, history, title, validateMenuActions]
  );

  const allQuantityTotal = useMemo(() => {
    return [
      {
        name: 'Monetary',
        data: [
          {
            title: 'Total reached of the goal',
            value: format(statisticsProductsMonetaryAllTotal.reached, 'ARS', t),
            icon: <AttachMoneyIcon style={{ fontSize: 50 }} color="primary" />
          },
          {
            title: 'Percentage of goal reached',
            value: `${statisticsProductsMonetaryAllTotal.objective > 0 ? ((statisticsProductsMonetaryAllTotal.reached * 100) / statisticsProductsMonetaryAllTotal.objective).toFixed(2) : 0}%`
          },
          {
            title: 'Total goal',
            value: format(statisticsProductsMonetaryAllTotal.objective, 'ARS', t),
            icon: <AttachMoneyIcon style={{ fontSize: 50 }} color="primary" />
          }
        ]
      },
      {
        name: 'Quantity',
        data: [
          {
            title: 'Total reached of the goal',
            value: format(statisticsProductCanalWithTotal.reached, 'Integer', t)
          },
          {
            title: 'Percentage of goal reached',
            value: `${statisticsProductCanalWithTotal.objective > 0 ? ((statisticsProductCanalWithTotal.reached * 100) / statisticsProductCanalWithTotal.objective).toFixed(2) : 0}%`
          },
          {
            title: 'Total goal',
            value: format(statisticsProductCanalWithTotal.objective, 'Integer', t)
          }
        ]
      }
    ];
  }, [t, statisticsProductCanalWithTotal, statisticsProductsMonetaryAllTotal]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.checked);
  };

  useEffect(() => {
    if (moment(filter.to).diff(moment(filter.from), 'days') > 7) setTypePeriod('month');
    else setTypePeriod('week');
  }, [filter.from, filter.to]);

  const columns = useMemo(() => [typePeriod === 'month' ? 'Week Number' : 'Days', 'Objective quantity', 'Sale quantity', 'Objective amount', 'Sale amount'], [typePeriod]);

  const handleColapseRow = (row: any, category: any) => {
    const week = Number(row.name.split('').reverse()[0]) || 0;
    setFilterSales({ week, day: !week ? row.name : '', category: category.name });
    setActiveFilterSales(true);
  };

  return (
    <ScreenAligned
      title={title}
      additionalTitle={
        <>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Switch checked={stateRefresh} onChange={handleChange} name="checkedA" color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} />
          </Box>
          {buttons
            ?.filter((dataButton: ButtonDetailsType) => !!dataButton.show)
            .map((dataButton: ButtonDetailsType) => (
              <ButtonComponent
                key={dataButton.id}
                title={dataButton.title}
                className={dataButton.className}
                variant={dataButton.variant}
                color={dataButton.color}
                onClick={dataButton.onClick}
                type={dataButton.type}
                disabled={dataButton.disabled}
                loading={dataButton.loading}
              />
            ))}
        </>
      }
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <SearchFilters initFetch={filter} onFilterChange={setFilter} dateFilter={DateFilter} dateFilterWorking={DateFilterWorking} />

        {allQuantityTotal.map((quantityTotal) => (
          <Box my={2} display="flex" width="100%" flexDirection="column">
            <Grid container xs={12} justify="space-between" direction="column">
              <Grid item xs={12}>
                <Typography variant="h5">{t(quantityTotal.name)}</Typography>
              </Grid>
              <Grid container xs={12} justify="space-between">
                {quantityTotal.data.map((dataTotal) => (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Box my={isMd ? 2 : 1} mx={isMd ? 2 : 1}>
                      <Card>
                        <Box display="flex" flexDirection="row" p={isMd ? 3 : 1} bgcolor="#fff1f4" alignItems="center">
                          {dataTotal.icon && (
                            <Box borderRadius="50%" borderColor="primary.main" border={1} mr={isMd ? 2 : 1}>
                              {dataTotal.icon}
                            </Box>
                          )}
                          <Box display="flex" flexDirection="column">
                            <Box height="100%" width="100%" mb={1}>
                              <Typography variant={isMd ? 'subtitle2' : 'body2'} color="primary">
                                {t(dataTotal.title).toUpperCase()}
                              </Typography>
                            </Box>
                            <Box height="100%" width="100%">
                              <Typography variant={isMd ? 'h2' : 'h4'} color="primary">
                                {dataTotal.value}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
        ))}

        <Grid container xs={12} spacing={isSm ? 4 : 2}>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Box bgcolor="white" p={isXs ? 2 : 4} height="100%" justifyContent="center">
              <Box height="10%">
                <Typography variant="h5">{t('Objective by billing')}</Typography>
              </Box>
              <Box display="flex" height="90%" justifyContent="center" alignItems="center" py={2}>
                <ComposedChartComponent working={workingData} dataChart={statisticsProductsCategory} bar={['objective', 'reached']} dataKeyXAxis="reached" dataFormatPayload={dataFormatPayload} />
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Box bgcolor="white" p={isXs ? 2 : 4} height="100%" justifyContent="center">
              <Box height="10%">
                <Typography variant="h5">{t('Goals by units')}</Typography>
              </Box>
              <Box display="flex" height="90%" justifyContent="center" alignItems="center" py={2}>
                <ComposedChartComponent working={workingData} dataChart={statisticsQuantityCategory} bar={['objective', 'reached']} dataKeyXAxis="reached" />
              </Box>
            </Box>
          </Grid>
          {!filter.account_id ? (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box bgcolor="white" p={isXs ? 2 : 4} height="100%" justifyContent="center">
                <Box height="10%">
                  <Typography variant="h5">{t('Goal by comercial')}</Typography>
                </Box>
                <Box display="flex" height="90%" justifyContent="center" alignItems="center" py={2}>
                  <ComposedChartComponent
                    working={workingData}
                    dataChart={orderBy(statisticsReportingObjectiveSaleUser, ['reached', 'objective'], ['desc'])}
                    bar={['objective', 'reached']}
                    dataKeyXAxis="reached"
                    dataFormatPayload={dataFormatPayload}
                  />
                </Box>
              </Box>
            </Grid>
          ) : null}
        </Grid>

        <Grid container xs={12} spacing={isSm ? 4 : 2}>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Box bgcolor="white" p={isXs ? 2 : 4} height="100%" justifyContent="center" mt={2}>
              <Box height="10%">
                <Typography variant="h5">{t(typePeriod === 'month' ? 'Weeks Information' : 'Days Information')}</Typography>
              </Box>
              <Box display="flex" height="90%" justifyContent="center" alignItems="center" py={2}>
                {!workingData ? <InfoCollapsibleTable columns={columns} rows={weeksInformation} primaryCollapsible="categoriesData" onColapseRowClick={handleColapseRow} /> : <LoadingData working={workingData} />}
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Box bgcolor="white" p={isXs ? 2 : 4} height="100%" justifyContent="center" mt={2}>
              <Box height="10%" display="flex" justifyContent="space-between">
                <Typography variant="h5">{t('Sales Execution')}</Typography>
                {activeFilterSales && (
                  <ButtonComponent
                    title={t('Reset the table')}
                    onClick={() => {
                      setFilterSales({ week: 0, day: '', category: '' });
                      setActiveFilterSales(false);
                    }}
                    variant="contained"
                    color="primary"
                  />
                )}
              </Box>
              <Box display="flex" height="90%" justifyContent="center" alignItems="center" py={2}>
                {!workingData ? <InfoTable columns={columnsReportObjetiveSales} rows={salesExecutions} /> : <LoadingData working={workingData} />}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </ScreenAligned>
  );
};
