import { AccountTargetless } from 'interfaces/business/account';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_account_targetless';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<AccountTargetless>(DAO_NAME);

// hooks
export const useAccountTargetlessFetchById = fetchById;
export const useAccountTargetlessLibbyCall = libbyCall;
export const useAccountTargetlessDAO = libbyDAO;
export const useAccountTargetlessLibbyFetch = libbyFetch;
