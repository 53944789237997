import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class AccountTargetlessDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_account_targetless', 'account_id');
  }

  getAll() {
    return this.query().run();
  }
}
