import { Filter } from '../../../../../types';

type MakeFilterReportingGoalSaleSettingFieldsAddProps = {
  search?: string;
};

export const makeFilterReportingGroupProductSaleSettingAdd = ({ search }: MakeFilterReportingGoalSaleSettingFieldsAddProps) => {
  const filter: Filter = {};
  if (search) {
    const searchArray = search.split(' ').reduce((accumulator: Filter, item, index) => {
      accumulator[index] = [
        {
          path: 'sku',
          value: search,
          method: 'includes'
        },
        {
          path: 'name',
          value: item,
          method: 'includes'
        }
      ];

      const numberSearch = parseInt(item, 10);
      if (numberSearch) {
        accumulator[index].push({
          path: 'product_id',
          value: numberSearch
        });
      }
      return accumulator;
    }, {});
    filter.search = [...searchArray[0]];
  }

  return filter;
};
