import { makeValidator, ValidatorRule, ValidatorType } from '../../../../../utils';

export const formReportingObjectivePeriodInitialValues = {
  group_id: '',
  quantity: '',
  amount: '',
  month_year: ''
};

const validations: ValidatorRule[] = [
  { key: 'group_id', validators: [ValidatorType.REQUIRED] },
  { key: 'quantity', validators: [ValidatorType.REQUIRED, ValidatorType.NUMBER, ValidatorType.POSITIVE] },
  { key: 'amount', validators: [ValidatorType.REQUIRED, ValidatorType.NUMBER, ValidatorType.POSITIVE] },
  { key: 'month_year', validators: [ValidatorType.REQUIRED] }
];

export const validateReportingObjectivePeriodAdd = makeValidator(validations);
