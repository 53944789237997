import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { orderBy as orderByLodash } from 'lodash';
import { useTranslation } from '../../../../../services/translation';
import { useBreadcrumbs, useCheckAll, useFilerParamsId } from '../../../../../hooks';
import { ProductsAvailable } from '../../../../../interfaces/business';
import { TitleBreadcrumbs } from '../../../../../interfaces';
import { ButtonDetailsType } from '../../../../../types/Button';
import { Column } from '../../../../components/InfoTable';
import { useProductsAvailableDAO, useProductsAvailableLibbyFetch } from 'business/ProductsAvailable';
import { RowsType } from '../../../../../utils/tables';

export const useReportingGroupProductSaleSettingsAddLogic = () => {
  const { group_id } = useParams<{ group_id: string }>();
  const productsDAO = useProductsAvailableDAO();
  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const selectTitle = t('Add product');
  const title = useBreadcrumbs(selectTitle);
  const titleShow = useBreadcrumbs(selectTitle, state && state?.goBack?.length ? state.goBack : title);

  const { orderBy, direction, handleRequestSort, setFilter, filter, paramsFetch } = useFilerParamsId({
    orderInit: 'name',
    daoName: 'products_available'
  });

  const { data: availableProducts, fetchMore, working } = useProductsAvailableLibbyFetch(paramsFetch);
  const { checked, columnAll, handleCheckId, resetCheck } = useCheckAll(availableProducts as ProductsAvailable[], 'sku');

  const addItemSelect = useCallback(async () => {
    try {
      const products = checked.all;
      const skuArray = products.map((product) => product.sku);
      await productsDAO.save({
        group_id: group_id,
        sku: skuArray
      });
    } catch (error) {
      enqueueSnackbar(t('Successfully added products'), { variant: 'success' });
      history.push({
        pathname: `/reporting-goal-offline-sales/setting/groups/products/${group_id}`,
        state: {
          goBack: state.goBack
        }
      });
    }
  }, [history, state.goBack, enqueueSnackbar, t, checked, productsDAO, group_id]);

  const rowsFields: RowsType[] = useMemo(() => {
    const dataProducts = availableProducts as ProductsAvailable[];
    const valueSearch = filter.search?.[0]?.value?.toLowerCase();
    const filteredProducts = dataProducts?.filter((product) => {
      const matchesSearch = valueSearch ? product.name.toLowerCase().includes(valueSearch) || product.sku.toLowerCase().includes(valueSearch) : true;
      return matchesSearch;
    });

    const rows = filteredProducts
      ? filteredProducts?.map((product: ProductsAvailable) => ({
          id: product.product_id,
          select_order_id: handleCheckId(product.sku),
          name: product.name,
          sku: product.sku
        }))
      : [];
    return orderByLodash(rows, [orderBy], [direction]);
  }, [handleCheckId, availableProducts, filter.search, direction, orderBy]);

  const buttonDetails = useMemo((): ButtonDetailsType[] => {
    return [
      {
        id: 'addFieldGoalSales',
        onClick: addItemSelect,
        title: t('Add'),
        type: 'submit',
        variant: 'contained',
        disabled: !checked.all.length
      }
    ];
  }, [t, addItemSelect, checked]);

  const columns: Array<Column> = [
    columnAll,
    {
      id: 'actions',
      label: '',
      width: 30
    },
    {
      id: 'name',
      label: t('Name')
    },
    {
      id: 'sku',
      label: 'Sku'
    }
  ];
  return { buttonDetails, selectTitle, rowsProducts: rowsFields, fetchMore, working, columns, checked, resetCheck, addItemSelect, orderBy, direction, handleRequestSort, setFilter, filter, titleShow };
};
