import { useMemo } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { AnyObject } from '../../types/types';
import { GeneralSelect } from '../GeneralSelect';
import { useTranslation } from '../../services/translation';
import { Group } from '../../interfaces/business';
import { useGroupLibbyCall } from 'business/group/Group';

type SelectGroupProps = {
  multiple?: boolean;
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  disabled?: boolean;
  sort?: boolean | 'asc' | 'desc';
  label?: string;
  variant: 'filled' | 'standard' | 'outlined';
};

export const SelectGroup = ({ multiple = false, inputProps = {}, optionAll = false, disabled = false, sort, variant, label, ...styles }: SelectGroupProps) => {
  const { t } = useTranslation();
  const { data: dataAll, working } = useGroupLibbyCall({
    methodName: 'getAllGroup'
  });

  const data = useMemo(() => {
    const dataGroup = dataAll as Group[];
    return dataGroup?.length
      ? dataGroup.map(({ group_id, name }: Group) => ({
          id: group_id,
          value: t(name)
        }))
      : [];
  }, [dataAll, t]);

  return (
    <>
      {multiple ? (
        <Autocomplete
          disabled={disabled}
          style={{ display: 'inline-block', width: '100%' }}
          multiple
          id="tags-outlined"
          options={data}
          getOptionLabel={(option: { id: string; value: string }) => option.value}
          filterSelectedOptions
          onChange={inputProps.onChange}
          renderInput={(params) => <TextField {...params} variant="standard" label={t('All the groups')} placeholder="" />}
        />
      ) : (
        <GeneralSelect variant={variant} disabled={disabled} working={working} label={t('Group name')} optionAll={optionAll} data={data} inputProps={inputProps} {...styles} sort={sort} />
      )}
    </>
  );
};
