import React, { useCallback, useMemo, MouseEvent, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useParams } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { useTranslation } from '../../../../../services/translation';
import { useGoalFetchById } from '../../../../../business/goal';
import { useBreadcrumbs, useFilerParamsId } from '../../../../../hooks';
import { RowsType } from '../../../../../utils/tables';
import CustomModal from '../../../../../services/customFormDialog';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import { ButtonDetailsType } from '../../../../../types/Button';
import { columnsReportingGoalSaleSettingFieldPeriods } from '../utils/columnsReportingGoalSaleSettingPeriods';
import { ActionTableDelete } from '../../../../../components/ActionTable';
import { TitleBreadcrumbs } from '../../../../../interfaces';
import { useGoalPeriodLibbyCall, useGoalPeriodLibbyFetch } from '../../../../../business/goal/GoalPeriod';
import { makeFilter } from '../utils/makeFilter';
import { GoalPeriod } from '../../../../../interfaces/business/goal/GoalPeriod';
import { useGoalPeriodUpdateDAO } from '../../../../../business/goal/GoalPeriodUpdated';
import { MenuActions, MenuItemComponentType } from '../../../../../components/MenuActions';
import { AddGoalPeriodForm, CreateGoalPeriodType } from '../components/AddGoalPeriodForm';
import { formReportingGoalPeriodInitialValues, validateReportingGoalPeriodAdd } from '../utils/modelReportingGoalSettingAdd';
import { DataPeriodNotEnabled } from '../type';
import { LibbyObject } from '../../../../../types';

const ConfirmModal = CustomModal(ConfirmDialog);

const AddGoalPeriodModal = CustomModal(AddGoalPeriodForm);

interface HandleChangeDataPeriodNotEnabled {
  goalNewDate?: Moment;
  goalOldDate?: Date;
  type: 'add' | 'updated' | 'delete';
}

interface useReportingGoalSaleSettingPeriodsLogicProps {
  libby: LibbyObject;
}

export const useReportingGoalSaleSettingPeriodsLogic = ({ libby }: useReportingGoalSaleSettingPeriodsLogicProps) => {
  const { goal_id } = useParams<{ goal_id: string }>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { data, working } = useGoalFetchById(goal_id);

  const { orderBy, direction, handleRequestSort, setFilter, paramsFetch } = useFilerParamsId({
    orderInit: 'start_date',
    directionInit: 'desc',
    daoName: 'ster_goal_period',
    init: makeFilter({ goal_id })
  });
  const { data: allDataPeriods, addCreate, updateData, removeData } = useGoalPeriodLibbyFetch(paramsFetch);
  const { data: dataPeriodNotEnabled, working: workingPeriodNotEnabled } = useGoalPeriodLibbyCall({ methodName: 'getAllGoalPeriodGroup', params: [{ goal_id }] });
  const [allDataPeriodNotEnabled, setAllDataPeriodNotEnabled] = useState<DataPeriodNotEnabled[]>([]);

  useEffect(() => {
    const dataPeriodNotEnableWithType = dataPeriodNotEnabled as GoalPeriod[] | undefined;
    if (dataPeriodNotEnableWithType?.length)
      setAllDataPeriodNotEnabled(
        dataPeriodNotEnableWithType?.reduce((allDataPeriod: DataPeriodNotEnabled[], { start_date }: GoalPeriod) => {
          const copyAllDataPeriod = [...allDataPeriod];
          copyAllDataPeriod.push({
            year: moment(start_date).format('YYYY'),
            quarter: moment(start_date).quarter()
          });
          return copyAllDataPeriod;
        }, [])
      );
  }, [dataPeriodNotEnabled]);

  const goalPeriodDAO = useGoalPeriodUpdateDAO();
  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();

  const title = useBreadcrumbs(`Goal period ${goal_id}`);
  const titleShow = useBreadcrumbs('Goal period', state && state?.goBack?.length ? state.goBack : title, goal_id);

  const handleChangeDataPeriodNotEnabled = useCallback(({ goalNewDate, goalOldDate, type }: HandleChangeDataPeriodNotEnabled) => {
    setAllDataPeriodNotEnabled((dataAllPeriodNotEnabled) => {
      const copyDataPeriodNotEnabled = [...dataAllPeriodNotEnabled];
      const newPeriod = {
        year: moment(goalNewDate).format('YYYY'),
        quarter: moment(goalNewDate).quarter()
      };
      if (type === 'updated' || type === 'delete') {
        const indexUpdated = copyDataPeriodNotEnabled.findIndex(({ year, quarter }: DataPeriodNotEnabled) => year === moment(goalOldDate).format('YYYY') && quarter === moment(goalOldDate).quarter());
        if (type === 'updated') copyDataPeriodNotEnabled[indexUpdated] = newPeriod;
        else copyDataPeriodNotEnabled.splice(indexUpdated, 1);
      } else {
        copyDataPeriodNotEnabled.push(newPeriod);
      }
      return copyDataPeriodNotEnabled;
    });
  }, []);

  const handleOnOpenGoalPeriod = useCallback(
    async (dataGoal?: GoalPeriod) => {
      try {
        const copyAllDataPeriodNotEnabled = [...allDataPeriodNotEnabled];
        const indexAllDataPeriodNotEnabledWithUpdated = dataGoal
          ? copyAllDataPeriodNotEnabled.findIndex(({ year, quarter }: DataPeriodNotEnabled) => year === moment(dataGoal.start_date).format('YYYY') && quarter === moment(dataGoal.start_date).quarter())
          : -1;

        if (indexAllDataPeriodNotEnabledWithUpdated > -1) {
          copyAllDataPeriodNotEnabled.splice(indexAllDataPeriodNotEnabledWithUpdated, 1);
        }

        const goalDataForm = (await AddGoalPeriodModal.show({
          confirmText: t(dataGoal ? 'Update' : 'Add'),
          cancelText: t('Cancel'),
          title: t(!dataGoal ? 'Add goal period' : 'Update goal period'),
          validate: validateReportingGoalPeriodAdd,
          allDataPeriodNotEnabled: copyAllDataPeriodNotEnabled,
          formInitialValues: dataGoal
            ? {
                year: dataGoal.start_date,
                trimester_date: moment(dataGoal.start_date).quarter(),
                value: dataGoal.value,
                month_year: moment(dataGoal.start_date).add(1, 'days')
              }
            : formReportingGoalPeriodInitialValues,
          goal: data && data,
          libby,
          editForm: !!dataGoal
        })) as CreateGoalPeriodType;

        if (goalDataForm) {
          const newGoal = await goalPeriodDAO.save({
            ...dataGoal,
            goal: { goal_id },
            start_date: goalDataForm.start_date,
            end_date: goalDataForm.end_date,
            value: goalDataForm.value,
            goal_range_type_id: data?.goal_period[0].goal_range_type_id
          });
          const message = dataGoal ? 'modified' : 'aggregated';
          if (!dataGoal) {
            addCreate(newGoal);
            handleChangeDataPeriodNotEnabled({
              goalNewDate: goalDataForm.start_date,
              type: 'add'
            });
          } else {
            updateData(newGoal, 'goal_period_id');
            handleChangeDataPeriodNotEnabled({
              goalNewDate: goalDataForm.start_date,
              goalOldDate: dataGoal.start_date,
              type: 'updated'
            });
          }
          enqueueSnackbar(`${t(`Goal $$$$ successfully ${message}`).replace('$$$$', newGoal.goal_period_id)}`, { variant: 'success' });
        }
      } catch (error) {
        // nothing
      }
    },
    [allDataPeriodNotEnabled, t, data, libby, goalPeriodDAO, goal_id, enqueueSnackbar, addCreate, handleChangeDataPeriodNotEnabled, updateData]
  );

  const buttonDetails = useMemo((): ButtonDetailsType[] => {
    const buttons: ButtonDetailsType[] = [
      {
        id: 'printDispatchNote',
        onClick: () => handleOnOpenGoalPeriod(),
        title: 'Add',
        type: 'submit',
        variant: 'contained',
        disabled: workingPeriodNotEnabled,
        loading: workingPeriodNotEnabled,
        show: data?.active
      }
    ];

    return buttons.filter((button) => button.show);
  }, [handleOnOpenGoalPeriod, workingPeriodNotEnabled, data]);

  const handleCancelModalMarketplace = useCallback(
    async (goalPeriod: GoalPeriod) => {
      const period = `${moment(goalPeriod.start_date).quarter()}-${moment(goalPeriod.start_date).format('YYYY')}`;
      try {
        const cancel = await ConfirmModal.show({
          title: t('Delete goal period'),
          content: `${t('You are about to remove the period $$$$ from the goal, do you want to continue?')}`.replace('$$$$', String(period)),
          confirmText: t('Confirm'),
          cancelText: t('Cancel')
        });
        if (cancel) {
          await goalPeriodDAO.remove({
            ...goalPeriod
          });
          handleChangeDataPeriodNotEnabled({
            goalOldDate: goalPeriod.start_date,
            type: 'delete'
          });
          removeData(goalPeriod.goal_period_id, 'goal_period_id');
          enqueueSnackbar(t('The goal period $$$$ from the goal').replace('$$$$', String(period)), { variant: 'success' });
        }
      } catch (error) {
        if (error) {
          enqueueSnackbar(`${t('Failed to delete')}: ${error}`, {
            variant: 'info'
          });
        }
      }
    },
    [enqueueSnackbar, goalPeriodDAO, t, removeData, handleChangeDataPeriodNotEnabled]
  );

  const menuArray = useCallback(
    (dataGoalPeriod: GoalPeriod): MenuItemComponentType[] => {
      const menu: MenuItemComponentType[] = [
        {
          title: 'Edit',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            handleOnOpenGoalPeriod(dataGoalPeriod);
          },
          disabled: false
        },
        {
          title: 'Delete',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            handleCancelModalMarketplace(dataGoalPeriod);
          },
          disabled: false,
          color: 'error'
        }
      ];
      return menu.filter((menuItems) => menuItems.disabled === false);
    },
    [handleCancelModalMarketplace, handleOnOpenGoalPeriod]
  );

  const rowsGoalPeriods: RowsType[] = useMemo(() => {
    const dataPeriods = allDataPeriods || ([] as GoalPeriod[]);
    return dataPeriods.map((dataPeriod: GoalPeriod) => {
      const { goal_period_id } = dataPeriod;
      return {
        id: goal_period_id,
        ...dataPeriod,
        delete: <ActionTableDelete onClick={() => handleCancelModalMarketplace(dataPeriod)} />,
        actions: <MenuActions menuArray={menuArray(dataPeriod)} />
      };
    });
  }, [allDataPeriods, menuArray, handleCancelModalMarketplace]);

  const columns = useMemo(() => {
    return data?.active
      ? columnsReportingGoalSaleSettingFieldPeriods.filter((column) => column.id !== 'Quarter')
      : columnsReportingGoalSaleSettingFieldPeriods.filter((column) => column.id !== 'actions' && column.id !== 'MonthYear');
  }, [data]);

  return { data, working, titleShow, buttonDetails, rows: rowsGoalPeriods, columns, orderBy, direction, handleRequestSort, setFilter };
};
