import { useCallback } from 'react';
import moment, { Moment } from 'moment';
import { useField, useForm } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';
import { FormProps } from '../../../../../types';
import { useTranslation, useLanguage } from '../../../../../services/translation';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../../../utils';
import { ResetForm } from '../../../../../components/ResetForm';
import { useModalFormStyles } from '../../../../../theme';
import { SelectGroup } from 'components/SelectGroup';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ObjectivePeriod } from 'interfaces/business';

export type CreateObjectivePeriodType = {
  group_id: string;
  year: number;
  month: number;
  quantity: number;
  amount: number;
};

interface ObjectiveFormProps extends FormProps {
  objectives?: ObjectivePeriod[];
}
export const AddObjectivePeriodForm = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {},
  objectives
}: ObjectiveFormProps) => {
  const { t } = useTranslation();
  const styles = useModalFormStyles();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);
  const { getLanguage } = useLanguage();
  const language = getLanguage();

  const onSubmit = useCallback(
    async ({ group_id, quantity, amount, month_year }) => {
      const { month, year } = month_year || {};

      onConfirm({
        year,
        month,
        group_id,
        quantity,
        amount
      });
    },
    [onConfirm]
  );

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate: (values: any) => {
      const errors: any = {};

      if (validate) {
        const propErrors = validate(values);
        Object.assign(errors, propErrors);
      }
      const { month_year } = values || {};
      const { month, year } = month_year || {};

      if (objectives?.some((objective) => objective.month === month && objective.year === year)) {
        errors.month_year = t('There is already a objective period created with that month and year');
      }

      return errors;
    }
  });

  const handleDateChange = (date: Moment | null) => {
    if (date) {
      const monthYearValue = {
        month: date.month() + 1,
        year: date.year()
      };
      month_year.input.onChange(monthYearValue);
    }
  };

  const group_id = useField('group_id', form);
  const month_year = useField('month_year', form);
  const quantity = useField('quantity', form);
  const amount = useField('amount', form);

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <DialogContent className={styles.title}>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          <SelectGroup
            variant="standard"
            inputProps={{
              name: 'group_id',
              onChange: group_id.input.onChange,
              value: group_id.input.value || '',
              ...getErrorAndHelperText(group_id)
            }}
          />

          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={language}>
            <DatePicker
              value={
                month_year.input.value
                  ? moment()
                      .year(month_year.input.value.year)
                      .month(month_year.input.value.month - 1)
                  : null
              }
              autoOk
              onChange={handleDateChange}
              views={['month', 'year']}
              fullWidth
              disablePast
              label={t('Month & year')}
              InputLabelProps={{
                shrink: true
              }}
              error={!!month_year.meta.error}
              helperText={month_year.meta.error || ''}
            />
          </MuiPickersUtilsProvider>

          <TextField
            name="quantity"
            {...textFieldProps(`${t('Quantity')}`)}
            onChange={quantity.input.onChange}
            value={quantity.input.value}
            variant="standard"
            multiline
            InputLabelProps={{
              shrink: true
            }}
            type="number"
            {...getErrorAndHelperText(quantity)}
          />

          <TextField
            name="amount"
            {...textFieldProps(`${t('Amount')}`)}
            onChange={amount.input.onChange}
            value={amount.input.value}
            variant="standard"
            multiline
            InputLabelProps={{
              shrink: true
            }}
            type="number"
            {...getErrorAndHelperText(amount)}
          />

          {submitError && (
            <Typography style={{ display: 'block' }} variant="overline" color="error">
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ResetForm form={form} open={open} />
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            {cancelText}
          </Button>
          <Button color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
