import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, TextField } from '@material-ui/core';
import { FilterBar } from '../../../../../components/FilterBar';
import { gridBreakpoints } from '../../../../../const/gridBreakpoints';
import { useInitFilterDebounced } from '../../../../OrderReport/routes/OrderReportList/hook';
import { makeFilter } from '../utils/makeFilter';
import { Filter } from '../../../../../types';
import { useTranslation } from '../../../../../services/translation';

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  filter: Filter;
};

const SearchFiltersRaw = ({ onFilterChange, filter }: SearchFiltersProps) => {
  const { t } = useTranslation();
  const { search, handleChange } = useInitFilterDebounced({
    initFetch: filter.search,
    init: '',
    onFilterChange,
    id: 'search',
    makeFilter
  });

  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Grid>
      }
    ></FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
