import { Objective } from '../../interfaces/business';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'objective_offline';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Objective>(DAO_NAME);

export const useObjectiveFetchById = fetchById;
export const useObjectiveLibbyCall = libbyCall;
export const useObjectiveDAO = libbyDAO;
export const useObjectiveLibbyFetch = libbyFetch;
