/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useCallback, useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { useField, useForm } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';
import { FormProps } from '../../../../../types';
import { useTranslation, useLanguage } from '../../../../../services/translation';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../../../utils';
import { ResetForm } from '../../../../../components/ResetForm';
import { SelectGroup } from '../../../../../components/SelectGroup';
import { SelectAccountTargetless } from 'components/SelectAccountTargetless';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { IconButton, Box } from '@material-ui/core';
import { ButtonComponent } from 'components/Button';
import { Objective } from '../../../../../interfaces/business';
import { useModalFormStyles } from './modalFormStyles';

export type CreateObjectiveType = {
  account_id: string;
  year: number;
  month: number;
  group_name: number;
  quantity: number;
  amount: number;
  groups: Group[];
};

interface Group {
  group_name: string;
  quantity: number;
  amount: number;
}

interface ObjectiveFormProps extends FormProps {
  objectives: Objective[];
}

export const AddObjectiveForm = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  formInitialValues = {}
}: ObjectiveFormProps) => {
  const { t } = useTranslation();
  const styles = useModalFormStyles();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);
  const { getLanguage } = useLanguage();
  const language = getLanguage();
  const [enableGroup, setEnabledGroup] = useState(false);
  const [messageErrorGroup, setMessageErrorGroup] = useState(false);
  const [groups, setGroups] = useState<Group[]>([]);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [currentGroup, setCurrentGroup] = useState<Group>({
    group_name: '',
    quantity: 0,
    amount: 0
  });

  useEffect(() => {
    form.change('groups', groups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  const onSubmit = useCallback(
    async ({ account_id, month_year, groups: groupList }) => {
      const { month, year } = month_year || {};

      onConfirm({
        account_id,
        year,
        month,
        groups: groupList
      });
    },
    [onConfirm]
  );

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate: (values: any) => {
      const errors: any = {};

      if (!values.account_id) {
        errors.account_id = t('Name is required');
      }
      if (!values.month_year) {
        errors.month_year = t('Month and year are required');
      }
      if (groups.length === 0) {
        errors.group_name = t('At least one group must be added');
      }
      return errors;
    }
  });

  const account_id = useField('account_id', form);
  const group_name = useField('group_name', form);
  const month_year = useField('month_year', form);
  const quantity = useField('quantity', form);
  const amount = useField('amount', form);

  const toggleEditGrupo = (index: number) => {
    setEditingIndex(editingIndex === index ? null : index);
  };

  const isEditing = editingIndex !== null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    switch (name) {
      case 'group_name':
        group_name.input.onChange(value);
        break;
      case 'quantity':
        quantity.input.onChange(value);
        break;
      case 'amount':
        amount.input.onChange(value);
        break;
      default:
        break;
    }

    setCurrentGroup({
      ...currentGroup,
      [name]: value
    });
  };

  const handleAddGroup = () => {
    if (!group_name.input.value || quantity.input.value <= 0 || amount.input.value <= 0) {
      setMessageErrorGroup(true);
      return;
    }
    setGroups([...groups, currentGroup]);
    setCurrentGroup({ group_name: '', quantity: 0, amount: 0 });
    group_name.input.onChange('');
    quantity.input.onChange('');
    amount.input.onChange('');
    setEnabledGroup(true);
    setMessageErrorGroup(false);
  };

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target;
    const updatedGroups = [...groups];
    updatedGroups[index] = {
      ...updatedGroups[index],
      [name]: value
    };

    if (!updatedGroups[index].group_name || updatedGroups[index].quantity <= 0 || updatedGroups[index].amount <= 0 || isNaN(updatedGroups[index].quantity) || isNaN(updatedGroups[index].amount)) {
      return;
    }
    setGroups(updatedGroups);
  };

  const handleDateChange = (date: Moment | null) => {
    if (date) {
      const monthYearValue = {
        month: date.month() + 1,
        year: date.year()
      };
      month_year.input.onChange(monthYearValue);
    }
  };

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <DialogContent className={styles.title}>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          <SelectAccountTargetless
            inputProps={{
              onChange: account_id.input.onChange,
              value: account_id.input.value || '',
              ...getErrorAndHelperText(account_id)
            }}
          />

          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={language}>
            <DatePicker
              value={
                month_year.input.value
                  ? moment()
                      .year(month_year.input.value.year)
                      .month(month_year.input.value.month - 1)
                  : null
              }
              autoOk
              onChange={handleDateChange}
              views={['month', 'year']}
              fullWidth
              label={t('Month & year')}
              disablePast
              InputLabelProps={{
                shrink: true
              }}
              {...getErrorAndHelperText(month_year)}
            />
          </MuiPickersUtilsProvider>

          {enableGroup && (
            <Box>
              {groups.map((group, index) => (
                <Box key={index}>
                  <Box sx={{ display: 'flex', width: '100%' }}>
                    <SelectGroup
                      disabled
                      variant="filled"
                      inputProps={{
                        name: 'group_name',
                        onChange: handleChange,
                        value: group.group_name,
                        ...getErrorAndHelperText(group_name)
                      }}
                    />

                    <IconButton onClick={() => toggleEditGrupo(index)} disabled={isEditing && editingIndex !== index}>
                      {' '}
                      +{' '}
                    </IconButton>
                  </Box>

                  <Box>
                    {editingIndex === index && (
                      <Box>
                        <SelectGroup
                          variant="standard"
                          inputProps={{
                            name: 'group_name',
                            onChange: (e: any) => handleEditChange(e, index),
                            value: groups[index].group_name,
                            ...getErrorAndHelperText(group_name)
                          }}
                        />

                        <TextField
                          name="quantity"
                          {...textFieldProps(`${t('Quantity')}`)}
                          onChange={(e: any) => handleEditChange(e, index)}
                          value={groups[index].quantity}
                          variant="standard"
                          multiline
                          InputLabelProps={{
                            shrink: true
                          }}
                          type="number"
                          {...getErrorAndHelperText(quantity)}
                        />

                        <TextField
                          name="amount"
                          {...textFieldProps(`${t('Amount')}`)}
                          onChange={(e: any) => handleEditChange(e, index)}
                          value={groups[index].amount}
                          variant="standard"
                          multiline
                          InputLabelProps={{
                            shrink: true
                          }}
                          type="number"
                          {...getErrorAndHelperText(amount)}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          <SelectGroup
            variant="standard"
            inputProps={{
              name: 'group_name',
              onChange: handleChange,
              value: group_name.input.value || '',
              ...getErrorAndHelperText(group_name)
            }}
          />

          <TextField
            name="quantity"
            {...textFieldProps(`${t('Quantity')}`)}
            onChange={handleChange}
            value={quantity.input.value}
            variant="standard"
            multiline
            InputLabelProps={{
              shrink: true,
              ...getErrorAndHelperText(quantity)
            }}
            type="number"
          />

          <TextField
            name="amount"
            {...textFieldProps(`${t('Amount')}`)}
            onChange={handleChange}
            value={amount.input.value}
            variant="standard"
            multiline
            InputLabelProps={{
              shrink: true,
              ...getErrorAndHelperText(amount)
            }}
            type="number"
          />
          {messageErrorGroup ? (
            <Typography variant="body2" color="error">
              {t('Complete the fields to add a group')}
            </Typography>
          ) : null}

          <ButtonComponent key="addGroup" title={t('Add group')} variant="contained" color="primary" type="button" disabled={isEditing} onClick={handleAddGroup} />

          {submitError && (
            <Typography style={{ display: 'block' }} variant="overline" color="error">
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ResetForm form={form} open={open} />
          <Button
            onClick={() => {
              onCancel();
              setGroups([]);
              setEditingIndex(null);
            }}
          >
            {cancelText}
          </Button>
          <Button disabled={isEditing} color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
