import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { MENU, MENU_ACTIONS } from '../../const';
import { useMenuAction } from '../../hooks/useMenuActions';
import { ReportingObjectiveSalesList, ReportingGroupSaleSetting, ReportingObjectiveSalesSettingList, ReportingObjectSaleSettingPeriod, ReportingGroupProductSaleSettingsAdd, ReportingGroupProductSaleSetting } from './routes';

export const ReportingObjectOfflineSales = () => {
  const match = useRouteMatch();
  const { validateMenuActions } = useMenuAction(MENU.REPORTING_OBJETIVE_OFFLINE_SALE);
  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <ReportingObjectiveSalesList />
      </Route>
      {validateMenuActions(MENU_ACTIONS.OFFLINE_OBJECTIVE_REPORTING_BUTTON.toString()) && (
        <>
          <Route exact path={`${match.path}/setting`}>
            <ReportingObjectiveSalesSettingList />
          </Route>
          <Route exact path={`${match.path}/setting/groups`}>
            <ReportingGroupSaleSetting />
          </Route>
          <Route exact path={`${match.path}/setting/groups/products/:group_id`}>
            <ReportingGroupProductSaleSetting />
          </Route>
          <Route exact path={`${match.path}/setting/groups/products/:group_id/add`}>
            <ReportingGroupProductSaleSettingsAdd />
          </Route>
          <Route exact path={`${match.path}/setting/period/:objective_id`}>
            <ReportingObjectSaleSettingPeriod />
          </Route>
        </>
      )}
    </Switch>
  );
};
