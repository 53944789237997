import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class ObjectivePeriodsDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_objective_period', 'objective_period_id');
  }

  getAll() {
    return this.query().run();
  }
}
