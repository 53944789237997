import { PartialBusinessEntityOptions } from '../../lib/libby/generator/makeBusinessEntity';

export const businessOptions: {
  [k: string]: PartialBusinessEntityOptions<any>;
} = {
  Company: {
    hoc: {
      withSimpleSelect: {
        labelKey: 'descripcionAnio',
        valueKey: 'idAnio',
        title: 'Año',
        placeholder: 'Seleccioná Año',
        name: 'idAnio'
      },
      withAccordionSelector: {
        idKey: 'idAnio',
        labelKey: 'descripcionAnio'
      },
      withSelectedChips: {
        idKey: 'idAnio',
        labelKey: 'descripcionAnio'
      }
    }
  },
  CompanyMarketplace: {
    hoc: {
      withSimpleSelect: {
        labelKey: 'descripcionAnio',
        valueKey: 'idAnio',
        title: 'Año',
        placeholder: 'Seleccioná Año',
        name: 'idAnio'
      },
      withAccordionSelector: {
        idKey: 'idAnio',
        labelKey: 'descripcionAnio'
      },
      withSelectedChips: {
        idKey: 'idAnio',
        labelKey: 'descripcionAnio'
      }
    }
  },
  Courier: {
    hoc: {
      withEntityAutocomplete: {
        optionLabelKey: 'name'
      }
    }
  },
  OrderShipmentState: {
    hoc: {
      withEntityAutocomplete: {
        optionLabelKey: 'name'
      }
    }
  }
};
