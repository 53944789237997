import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ProductsDAO extends LibbyFetchDAO {
  constructor() {
    super('groups_product', 'group_id');
  }

  getAll() {
    return this.query().run();
  }
}
