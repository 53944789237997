export enum MARKETPLACE {
  FRAVEGA = '1',
  NOVATECH_STORE = '2',
  BNA = '4',
  BNA_UNIVERSITARIOS = '5',
  TARJETA_NARANJA = '6',
  PAMI = '7',
  CORREO_COMPRAS = '8',
  BANCO_PROVINCIA = '9',
  TIENDA_BNA = '10',
  TIENDA_OFICIAL_ML = '11',
  NOVATECH_MELI = '12',
  BANCOR = '13',
  ENOVA = '14',
  VENTA_TELEFONICA = '21',
  NECXUS = '23',
  ICBC = '24',
  TIENDA_COLUMBIA = '25',
  BBVA = '26',
  SUPERVILLE = '27',
  BANCO_CIUDAD = '30',
  DETECNOLOGIA = '31',
  AVENIDA = '32',
  PASEO_LIBERTAD = '33',
  SAN_JUSTO_SHOPPING = '34',
  URBANTECH_MELI = '36',
  MEGATONE = '37',
  URBANTECH_MSHOPS = '38',
  SHOPEE = '42',
  TIENDA_CLICK = '45',
  MEGUSTA = '46',
  BNA_CONECTA = '47',
  GARBARINO = '50',
  EMOOD = '51',
  MI_CREDITO_BNA = '52',
  TIENDA_IUPP = '54',
  TIENDA_GALICIA = '55',
  TIENDA_BNA_PROGRAMADORES = '56',
  CETROGAR = '57',
  CARREFOUR = '58',
  BNA_MOVIL = '61',
  MACRO_PREMIA = '62'
}

export const Marketplace: { [k: string]: string } = {
  1: 'Fravega',
  2: 'NovatechStore',
  3: 'ICBC',
  4: 'BNA',
  5: 'BNA Universitarios',
  6: 'Tarjeta Naranja',
  7: 'PAMI',
  8: 'Correo Compras',
  9: 'Banco Provincia',
  10: 'Tienda BNA'
};

export const MARKETPLACE_BILLING = [
  {
    name: 'BNA',
    marketplace_id: MARKETPLACE.BNA
  },
  {
    name: 'BNA_Universitarios',
    marketplace_id: MARKETPLACE.BNA_UNIVERSITARIOS
  },
  {
    name: 'BNA_Conecta',
    marketplace_id: MARKETPLACE.BNA_CONECTA
  },
  {
    name: 'PAMI',
    marketplace_id: MARKETPLACE.PAMI
  },
  {
    name: 'Banco Provincia',
    marketplace_id: MARKETPLACE.BANCO_PROVINCIA
  },
  {
    name: 'Bancor',
    marketplace_id: MARKETPLACE.BANCOR
  },
  {
    name: 'Correo Compras',
    marketplace_id: MARKETPLACE.CORREO_COMPRAS
  },
  {
    name: 'Venta Telefónica',
    marketplace_id: MARKETPLACE.VENTA_TELEFONICA
  },
  {
    name: 'Tienda Columbia',
    marketplace_id: MARKETPLACE.TIENDA_COLUMBIA
  },
  {
    name: 'ICBC',
    marketplace_id: MARKETPLACE.ICBC
  },
  {
    name: 'BBVA',
    marketplace_id: MARKETPLACE.BBVA
  },
  {
    name: 'Superville',
    marketplace_id: MARKETPLACE.SUPERVILLE
  },
  {
    name: 'Avenida',
    marketplace_id: MARKETPLACE.AVENIDA
  },
  {
    name: 'Paseo Libertad',
    marketplace_id: MARKETPLACE.PASEO_LIBERTAD
  },
  {
    name: 'San Justo Shopping',
    marketplace_id: MARKETPLACE.SAN_JUSTO_SHOPPING
  },
  {
    name: 'Shopee',
    marketplace_id: MARKETPLACE.SHOPEE
  },
  {
    name: 'Tienda Oficial ML',
    marketplace_id: MARKETPLACE.TIENDA_OFICIAL_ML,
    noFile: true,
    marketplace_type: 'ML'
  },
  {
    name: 'Novatech Meli',
    marketplace_id: MARKETPLACE.NOVATECH_MELI,
    noFile: true,
    marketplace_type: 'ML'
  },
  {
    name: 'Urbantech Meli',
    marketplace_id: MARKETPLACE.URBANTECH_MELI,
    noFile: true,
    marketplace_type: 'ML'
  },
  {
    name: 'URBANTECH MShops',
    marketplace_id: MARKETPLACE.URBANTECH_MSHOPS,
    noFile: true,
    marketplace_type: 'ML'
  },
  {
    name: 'Frávega',
    marketplace_id: MARKETPLACE.FRAVEGA
  },
  {
    name: 'Megatone',
    marketplace_id: MARKETPLACE.MEGATONE
  },
  {
    name: 'Tienda BNA',
    marketplace_id: MARKETPLACE.TIENDA_BNA
  },
  {
    name: 'Necxus',
    marketplace_id: MARKETPLACE.NECXUS
  },
  {
    name: 'Tienda Ciudad',
    marketplace_id: MARKETPLACE.BANCO_CIUDAD
  },
  {
    name: 'Tienda Click',
    marketplace_id: MARKETPLACE.TIENDA_CLICK
  },
  {
    name: 'Garbarino',
    marketplace_id: MARKETPLACE.GARBARINO
  },
  {
    name: 'Emood',
    marketplace_id: MARKETPLACE.EMOOD
  },
  {
    name: 'Enova - MP',
    marketplace_id: `mercadopago-${MARKETPLACE.ENOVA}`,
    marketplace_type: 'MP',
    noFile: true
  },
  {
    name: 'Enova',
    marketplace_id: MARKETPLACE.ENOVA
  },
  {
    name: 'Novatech - MP',
    marketplace_id: `mercadopago-${MARKETPLACE.NOVATECH_STORE}`,
    marketplace_type: 'MP',
    noFile: true
  },
  {
    name: 'Novatech',
    marketplace_id: MARKETPLACE.NOVATECH_STORE
  },
  {
    name: 'Detecnologia',
    marketplace_id: MARKETPLACE.DETECNOLOGIA,
    marketplace_type: 'MP'
  },
  {
    name: 'Mi credito BNA',
    marketplace_id: MARKETPLACE.MI_CREDITO_BNA
  },
  {
    name: 'Tienda Iupp',
    marketplace_id: MARKETPLACE.TIENDA_IUPP
  },
  {
    name: 'Tienda Galicia',
    marketplace_id: MARKETPLACE.TIENDA_GALICIA
  },
  {
    name: 'Cetrogar',
    marketplace_id: MARKETPLACE.CETROGAR
  },
  {
    name: 'Carrefour',
    marketplace_id: MARKETPLACE.CARREFOUR
  },
  {
    name: 'BNA Movil',
    marketplace_id: MARKETPLACE.BNA_MOVIL
  },
  {
    name: 'Macro Premia',
    marketplace_id: MARKETPLACE.MACRO_PREMIA
  }
];

export const FULLJAUS_STOCK = [
  MARKETPLACE.TIENDA_BNA,
  MARKETPLACE.TIENDA_BNA_PROGRAMADORES,
  MARKETPLACE.ICBC,
  MARKETPLACE.BANCO_CIUDAD,
  MARKETPLACE.SUPERVILLE,
  MARKETPLACE.TIENDA_CLICK,
  MARKETPLACE.MEGUSTA,
  MARKETPLACE.GARBARINO,
  MARKETPLACE.EMOOD,
  MARKETPLACE.TIENDA_COLUMBIA,
  MARKETPLACE.BBVA,
  MARKETPLACE.TIENDA_IUPP,
  MARKETPLACE.TIENDA_GALICIA
];
