import React, { useMemo } from 'react';
import { Box, Grid, Typography, TextField } from '@material-ui/core';
import { MarketplaceFraud } from 'interfaces/business';
import { Collapse } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'services/translation';
import { useForm, useField } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import { CheckBox } from 'components/CheckBok';
import { makeStyles } from '@material-ui/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useFraudSettingsFormLogic } from './hooks';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  menuButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
    backgroundColor: '#ff5179',
    color: 'white',
    fontSize: '14px',
    borderRadius: '5px',
    cursor: 'pointer',
    height: '35px',
    marginBottom: 1,
    '&:hover': {
      backgroundColor: '#972d49'
    }
  },
  titleDiv: {
    width: '100%',
    textAlign: 'center',
    color: 'primary'
  },
  outterDiv: {
    width: '100%',
    minHeight: '280px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  formBox: {
    width: '100%',
    minHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
}));

type SelectImportTypeProps = {
  marketplace_fraud: MarketplaceFraud;
  onConfirm: any;
};
type OpenCollapseType = {
  fraud_match: boolean;
  dni_validation: boolean;
  order_item_validation: boolean;
};
export type ActiveStateType = {
  fraud_match: boolean;
  orders_in_range: boolean;
  different_address: boolean;
  same_address: boolean;
  multiple_ot: boolean;
  multiple_quantity: boolean;
  ot_price: boolean;
};
const initialOpenCollapseState = {
  fraud_match: false,
  dni_validation: false,
  order_item_validation: false
};

export const FraudSettingsForm = ({ marketplace_fraud, onConfirm }: SelectImportTypeProps) => {
  const classes = useStyles();
  const initialActiveState = useMemo(
    () => ({
      fraud_match: !!marketplace_fraud.fraud_config.fraud_match || false,
      orders_in_range: !!marketplace_fraud.fraud_config.dni_validation?.orders_in_range || false,
      different_address: !!marketplace_fraud.fraud_config.dni_validation?.different_address || false,
      same_address: !!marketplace_fraud.fraud_config.dni_validation?.same_address || false,
      multiple_ot: !!marketplace_fraud.fraud_config.order_item_validation?.multiple_ot || false,
      multiple_quantity: !!marketplace_fraud.fraud_config.order_item_validation?.multiple_quantity || false,
      ot_price: !!marketplace_fraud.fraud_config.order_item_validation?.ot_price || false
    }),
    [marketplace_fraud.fraud_config]
  );
  const [openCollapse, setOpenCollapse] = useState<OpenCollapseType>(initialOpenCollapseState);
  const [active, setActive] = useState<ActiveStateType>(initialActiveState);
  const { t } = useTranslation();
  const onSubmit = async ({ document, name, address, email, phone, from, to, rows_ammount, documents_amount, multiple_ot_amount, multiple_quantity, ot_price }: any) => {
    const fraud_match = active.fraud_match
      ? {
          document,
          name,
          address,
          email,
          phone
        }
      : false;
    const orders_in_range = active.orders_in_range
      ? {
          from,
          to
        }
      : false;
    const different_address = active.different_address
      ? {
          rows_ammount
        }
      : false;
    const same_address = active.same_address
      ? {
          documents_amount
        }
      : false;
    const multiple_ot = active.multiple_ot
      ? {
          amount: multiple_ot_amount
        }
      : false;
    const multipleQuantity = active.multiple_quantity
      ? {
          multiple_quantity
        }
      : false;
    const otPrice = active.ot_price
      ? {
          ot_price: ot_price
        }
      : false;

    const finalObject = {
      fraud_match,
      dni_validation: {
        orders_in_range,
        different_address,
        same_address
      },
      order_item_validation: {
        multiple_ot,
        multiple_quantity: multipleQuantity,
        ot_price: otPrice
      }
    };
    onConfirm(finalObject);
  };

  const { validate } = useFraudSettingsFormLogic({ active });
  const { form, handleSubmit } = useForm({
    initialValues: {
      document: marketplace_fraud.fraud_config?.fraud_match?.document || false,
      name: marketplace_fraud.fraud_config?.fraud_match?.name || false,
      address: marketplace_fraud.fraud_config?.fraud_match?.address || false,
      email: marketplace_fraud.fraud_config?.fraud_match?.email || false,
      phone: marketplace_fraud.fraud_config?.fraud_match?.phone || false,
      from: marketplace_fraud.fraud_config?.dni_validation?.orders_in_range?.from ? moment(marketplace_fraud.fraud_config?.dni_validation?.orders_in_range?.from).format('YYYY-MM-DD') : '',
      to: marketplace_fraud.fraud_config?.dni_validation?.orders_in_range?.to ? moment(marketplace_fraud.fraud_config?.dni_validation?.orders_in_range?.to).format('YYYY-MM-DD') : '',
      rows_ammount: marketplace_fraud.fraud_config?.dni_validation?.different_address?.rows_ammount || '',
      documents_amount: marketplace_fraud.fraud_config?.dni_validation?.same_address?.documents_amount || '',
      multiple_ot_amount: marketplace_fraud.fraud_config?.order_item_validation?.multiple_ot?.amount || '',
      multiple_quantity: marketplace_fraud.fraud_config?.order_item_validation?.multiple_quantity?.quantity || '',
      ot_price: marketplace_fraud.fraud_config?.order_item_validation?.ot_price?.price || ''
    },
    onSubmit,
    validate
  });

  const document = useField('document', form);
  const name = useField('name', form);
  const address = useField('address', form);
  const email = useField('email', form);
  const phone = useField('phone', form);
  const from = useField('from', form);
  const to = useField('to', form);
  const rows_ammount = useField('rows_ammount', form);
  const documents_amount = useField('documents_amount', form);
  const multiple_ot_amount = useField('multiple_ot_amount', form);
  const multiple_quantity = useField('multiple_quantity', form);
  const ot_price = useField('ot_price', form);
  return (
    <Box sx={{ width: '400px', height: '315px' }}>
      <form onSubmit={handleSubmit}>
        <Box className={classes.outterDiv}>
          <Box className={classes.formBox}>
            <Box className={classes.menuButton} onClick={() => setOpenCollapse((prevState) => ({ ...initialOpenCollapseState, fraud_match: !prevState.fraud_match }))}>
              {t('Fraud Match')}
            </Box>
            <Collapse in={openCollapse.fraud_match} timeout="auto">
              <FormControlLabel
                control={<Switch checked={active.fraud_match} name="fraud_match" color="primary" onChange={() => setActive((prevState) => ({ ...prevState, fraud_match: !prevState.fraud_match }))} />}
                label={<Typography className={classes.titleDiv}>{t('Match orders fraud by:')}</Typography>}
              />
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <CheckBox
                    disabled={!active.fraud_match}
                    validateCheck={document.input.value}
                    directionLabel="row-reverse"
                    label="Document"
                    onChange={() => {
                      document.input.onChange(!document.input.value);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CheckBox
                    disabled={!active.fraud_match}
                    validateCheck={name.input.value}
                    directionLabel="row-reverse"
                    label="Name"
                    onChange={() => {
                      name.input.onChange(!name.input.value);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CheckBox
                    disabled={!active.fraud_match}
                    validateCheck={email.input.value}
                    directionLabel="row-reverse"
                    label="Email"
                    onChange={() => {
                      email.input.onChange(!email.input.value);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CheckBox
                    disabled={!active.fraud_match}
                    validateCheck={phone.input.value}
                    directionLabel="row-reverse"
                    label="Phone"
                    onChange={() => {
                      phone.input.onChange(!phone.input.value);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CheckBox
                    disabled={!active.fraud_match}
                    validateCheck={address.input.value}
                    directionLabel="row-reverse"
                    label="Address"
                    onChange={() => {
                      address.input.onChange(!address.input.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Collapse>
            <Box className={classes.menuButton} onClick={() => setOpenCollapse((prevState) => ({ ...initialOpenCollapseState, dni_validation: !prevState.dni_validation }))}>
              {t('DNI Validation')}
            </Box>
            <Collapse in={openCollapse.dni_validation} timeout="auto">
              <FormControlLabel
                control={<Switch checked={active.orders_in_range} name="orders_in_range" color="primary" onChange={() => setActive((prevState) => ({ ...prevState, orders_in_range: !prevState.orders_in_range }))} />}
                label={<Typography className={classes.titleDiv}>{t('Orders in range')}</Typography>}
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="date"
                    label={t('From')}
                    type="date"
                    value={from.input.value}
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={!active.orders_in_range}
                    helperText={from.meta.submitFailed && !!from.meta.error ? from.meta.error : ''}
                    error={from.meta.submitFailed && !!from.meta.error}
                    onChange={(date) => from.input.onChange(date)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="date"
                    label={t('To')}
                    type="date"
                    value={to.input.value}
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={!active.orders_in_range}
                    helperText={to.meta.submitFailed && !!to.meta.error ? to.meta.error : ''}
                    error={to.meta.submitFailed && !!to.meta.error}
                    onChange={(date) => to.input.onChange(date)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Switch checked={active.different_address} name="different_address" color="primary" onChange={() => setActive((prevState) => ({ ...prevState, different_address: !prevState.different_address }))} />}
                    label={<Typography className={classes.titleDiv}>{t('Different address')}</Typography>}
                  />
                  <TextField
                    id="number"
                    label={t('Rows amount')}
                    type="number"
                    value={rows_ammount.input.value}
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={!active.different_address}
                    helperText={rows_ammount.meta.submitFailed && !!rows_ammount.meta.error ? rows_ammount.meta.error : ''}
                    error={rows_ammount.meta.submitFailed && !!rows_ammount.meta.error}
                    onChange={(e) => rows_ammount.input.onChange(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Switch checked={active.same_address} name="same_address" color="primary" onChange={() => setActive((prevState) => ({ ...prevState, same_address: !prevState.same_address }))} />}
                    label={<Typography className={classes.titleDiv}>{t('Same address')}</Typography>}
                  />
                  <TextField
                    id="number"
                    label={t('Documents amount')}
                    type="number"
                    value={documents_amount.input.value}
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={!active.same_address}
                    helperText={documents_amount.meta.submitFailed && !!documents_amount.meta.error ? documents_amount.meta.error : ''}
                    error={documents_amount.meta.submitFailed && !!documents_amount.meta.error}
                    onChange={(e) => documents_amount.input.onChange(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Collapse>
            <Box className={classes.menuButton} onClick={() => setOpenCollapse((prevState) => ({ ...initialOpenCollapseState, order_item_validation: !prevState.order_item_validation }))}>
              {t('Item Validation')}
            </Box>
            <Collapse in={openCollapse.order_item_validation} timeout="auto">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Switch checked={active.multiple_ot} name="multiple_ot" color="primary" onChange={() => setActive((prevState) => ({ ...prevState, multiple_ot: !prevState.multiple_ot }))} />}
                    label={<Typography className={classes.titleDiv}>{t('Multiple products')}</Typography>}
                  />
                  <TextField
                    id="number"
                    label={t('Products amount')}
                    type="number"
                    value={multiple_ot_amount.input.value}
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={!active.multiple_ot}
                    helperText={multiple_ot_amount.meta.submitFailed && !!multiple_ot_amount.meta.error ? multiple_ot_amount.meta.error : ''}
                    error={multiple_ot_amount.meta.submitFailed && !!multiple_ot_amount.meta.error}
                    onChange={(e) => multiple_ot_amount.input.onChange(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Switch checked={active.multiple_quantity} name="multiple_quantity" color="primary" onChange={() => setActive((prevState) => ({ ...prevState, multiple_quantity: !prevState.multiple_quantity }))} />}
                    label={<Typography className={classes.titleDiv}>{t('Multiple quantity')}</Typography>}
                  />
                  <TextField
                    id="number"
                    label={t('Item quantity')}
                    type="number"
                    value={multiple_quantity.input.value}
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={!active.multiple_quantity}
                    helperText={multiple_quantity.meta.submitFailed && !!multiple_quantity.meta.error ? multiple_quantity.meta.error : ''}
                    error={multiple_quantity.meta.submitFailed && !!multiple_quantity.meta.error}
                    onChange={(e) => multiple_quantity.input.onChange(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Switch checked={active.ot_price} name="ot_price" color="primary" onChange={() => setActive((prevState) => ({ ...prevState, ot_price: !prevState.ot_price }))} />}
                    label={<Typography className={classes.titleDiv}>{t('Item price')}</Typography>}
                  />
                  <TextField
                    id="number"
                    label={t('Price')}
                    type="number"
                    value={ot_price.input.value}
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={!active.ot_price}
                    helperText={ot_price.meta.submitFailed && !!ot_price.meta.error ? ot_price.meta.error : ''}
                    error={ot_price.meta.submitFailed && !!ot_price.meta.error}
                    onChange={(e) => ot_price.input.onChange(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Box>
        </Box>
        <Button fullWidth variant="outlined" color="primary" type="submit">
          {t('Save')}
        </Button>
      </form>
    </Box>
  );
};
