import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class ObjectiveDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_objective', 'objective_id');
  }

  getAll() {
    return this.query().run();
  }
}
