import { LibbyFetchDAO } from './LibbyFetchDAO';

export class OrderShipmentStateDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_shipment_state', 'order_shipment_state_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
