import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ObjectiveValDAO extends LibbyFetchDAO {
  constructor() {
    super('objective_values', 'objective_period_id');
  }

  getAll() {
    return this.query().run();
  }
}
