import { Order_shipment_state } from '../../../interfaces/business';
import { makeBusinessEntity, PartialBusinessEntityOptions } from '../../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_order_shipment_state';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withEntityAutocomplete: {
      optionLabelKey: 'name'
    }
  }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete }
} = makeBusinessEntity<Order_shipment_state>(DAO_NAME, defaultOptions);

// hooks
export const useOrderShipmentStateFetchById = fetchById;
export const useOrderShipmentStateLibbyCall = libbyCall;
export const useOrderShipmentStateDAO = libbyDAO;
export const useOrderShipmentStateLibbyFetch = libbyFetch;

// components/hoc
export const OrderShipmentStateAutocomplete = entityAutocomplete;
