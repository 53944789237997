import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ProductsAvailableDAO extends LibbyFetchDAO {
  constructor() {
    super('products_available', 'product_id');
  }

  getAll() {
    return this.query().run();
  }
}
