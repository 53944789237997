import { Filter } from '../../../../../types';

type MakeFilterProps = {
  search?: string;
};

export const makeFilter = ({ search }: MakeFilterProps) => {
  const filter: Filter = {};
  if (search) {
    const searchArray = search.split(' ').reduce((accumulator: Filter, item, index) => {
      accumulator[index] = [
        {
          path: 'name',
          value: search,
          method: 'includes'
        }
      ];

      return accumulator;
    }, {});
    filter.search = [...searchArray[0]];
  }

  return filter;
};
