import { Group } from '../../interfaces/business';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_group';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Group>(DAO_NAME);

export const useGroupFetchById = fetchById;
export const useGroupLibbyCall = libbyCall;
export const useGroupDAO = libbyDAO;
export const useGroupLibbyFetch = libbyFetch;
