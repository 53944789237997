import { useMemo } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { AnyObject } from '../../types/types';
import { GeneralSelect } from '../GeneralSelect';
import { useTranslation } from '../../services/translation';
import { AccountTargetless } from 'interfaces/business/account';
import { useAccountTargetlessLibbyCall } from 'business/account/AccountTargetless';

type SelectAccountTargetless = {
  multiple?: boolean;
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  disabled?: boolean;
  sort?: boolean | 'asc' | 'desc';
  label?: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SelectAccountTargetless = ({ multiple = false, inputProps = {}, optionAll = false, disabled = false, sort, label, ...styles }: SelectAccountTargetless) => {
  const { t } = useTranslation();
  const { data: dataAll, working } = useAccountTargetlessLibbyCall({
    methodName: 'getAll'
  });

  const data = useMemo(() => {
    const dataAccount = dataAll as AccountTargetless[];
    return dataAccount?.length
      ? dataAccount
          .filter(({ full_name }) => full_name && full_name.trim() !== '')
          .map(({ account_id, full_name }: AccountTargetless) => ({
            id: account_id,
            value: full_name
          }))
      : [];
  }, [dataAll]);

  return (
    <>
      {multiple ? (
        <Autocomplete
          disabled={disabled}
          style={{ display: 'inline-block', width: '100%' }}
          multiple
          id="tags-outlined"
          options={data}
          getOptionLabel={(option: { id: string; value: string }) => option.value}
          filterSelectedOptions
          onChange={inputProps.onChange}
          renderInput={(params) => <TextField {...params} variant="standard" label={t('All names')} placeholder="" />}
        />
      ) : (
        <GeneralSelect disabled={disabled} working={working} label={t('Name')} optionAll={optionAll} data={data} inputProps={inputProps} {...styles} sort={sort} />
      )}
    </>
  );
};
