import { ObjectiveVal } from '../../interfaces/business';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'objective_values';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<ObjectiveVal>(DAO_NAME);

// hooks
export const useObjectiveValFetchById = fetchById;
export const useObjectiveValLibbyCall = libbyCall;
export const useObjectiveValDAO = libbyDAO;
export const useObjectiveValLibbyFetch = libbyFetch;
