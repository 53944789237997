/* eslint-disable */
import { useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, CircularProgress } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useSnackbar } from 'notistack';
import { AnyObject, LibbyObject, userInfoType } from '../../../types';
import { makeMapLabelValues } from '../../utils/mappers';
import { useTranslation } from '../../../services/translation';
import { InformationSection } from '../../../components/InformationSection';
import { ItemsTable } from '../ItemsTable';
import { DocumentsTable } from '../DocumentsTable';
import { gridBreakpointsOrderDetails } from '../../../const/gridBreakpoints';
import { useSendOrderEdit } from '../../Orders/routes/OrderDetail/hook/useSendOrderEdit';
import { useGetStates } from '../../Orders/routes/OrderDetail/hook';
import { MARKETPLACE, MENU_ACTIONS, ORDER_DOCUMENT_TYPE, ORDER_STATE } from '../../../const';
import { useGlobalContext } from '../../../hooks';
import { FileInput } from '../../Budget/routes/BudgetFile/components/FileInput';
import { ORDER_DOCUMENT_SOURCE } from '../../../const/OrderSources';
import { useStyles } from './styles';
import { basicInformationMap, basicInformationMapWithPaymentGateway, basicInformationMapWithPaymentMethod, discountMap } from './utils';
import { shipmentMap } from './utils/shipmentMap';
import { buyerMap } from './utils/buyerDirection';
import { useBudget } from './hooks';
import { matchOrderState } from '../../../utils';
import { useMenuAction } from 'hooks/useMenuActions';
import { paymentMethodMap } from './utils/paymentMethodMap';
import { useOrderRetentionReasonLibbyCall } from 'business/orders/order/OrderRetentionReason';
import { OrderRetentionReason } from 'interfaces/business';

interface OrderDetailDataInterface {
  data: AnyObject;
  libby: LibbyObject;
  onStateChange: (stateId: number) => void;
  recall: ((...newParams: any[]) => Promise<any>) | undefined;
  operationData: AnyObject;
}

const MARKETPLACE_PAYMENT_GATEWAY_ENABLED = [MARKETPLACE.ENOVA, MARKETPLACE.NOVATECH_STORE, MARKETPLACE.DETECNOLOGIA, MARKETPLACE.VENTA_TELEFONICA];

export const OrderDetailDataRaw = ({ data, libby, onStateChange, recall, operationData }: OrderDetailDataInterface) => {
  const { validateAction } = useMenuAction();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();
  const mapperLabelValues = makeMapLabelValues(t);
  const [newDocuments, setNewDocuments] = useState();
  const {
    userInfo
  }: {
    userInfo: userInfoType;
  } = useGlobalContext();

  const { approveBudget, approving, onAcceptBudget } = useBudget({ data, recall, onStateChange, operationData });
  const { data: dataRetention } = useOrderRetentionReasonLibbyCall({ methodName: 'getAll' });
  const retentionOrder = (dataRetention as OrderRetentionReason[])?.find((order) => order.order_id === Number(data.order_id));

  const discountInfo = {
    name: 'TIENDAENOVATARJETA365',
    value: 5
  };

  const isPaymentGateway = useMemo(() => MARKETPLACE_PAYMENT_GATEWAY_ENABLED.includes(data.source?.marketplace?.marketplace_id?.toString()), [data]);
  const basicMapper = mapperLabelValues(isPaymentGateway ? basicInformationMapWithPaymentGateway : data?.orderB2Bs?.length ? basicInformationMapWithPaymentMethod : basicInformationMap);

  const buyerMapper = mapperLabelValues(buyerMap);
  const shipmentMapper = mapperLabelValues(shipmentMap);
  const discountMapper = mapperLabelValues(discountMap);
  const paymentMethodMapper = mapperLabelValues(paymentMethodMap);
  const location = useLocation();

  const goToEditBudget = useCallback(() => {
    history.push(`/budget/edit/${params?.id}`);
  }, [history, params]);

  const goToCommentsBudget = useCallback(() => {
    history.push(`/budget/comments/${params?.id}`);
  }, [history, params]);

  const verificatePathname = useMemo(() => location.pathname.split('/'), [location.pathname]);

  const budgetEditPermission = useMemo(() => data && data?.orderB2Bs?.length && data?.orderB2Bs[0]?.createdByAccount?.account_id?.toString() === userInfo?.id, [data, userInfo]);

  const { states } = useGetStates({ libby });

  const handleGetCity = useCallback(
    async (city: string, province: string) => {
      const cityFetch = await libby?.ster_city.query().equals('name', city).equals('state.name', province).run();
      return cityFetch;
    },
    [libby]
  );

  const { handleModal, loading } = useSendOrderEdit({
    data,
    libby,
    states,
    handleGetCity,
    recall
  });

  const uploadFile = useCallback(
    async (filename: string, base64Content: string, extension: string) => {
      try {
        await libby.ster_order_document.save({
          order: {
            order_id: params.id
          },
          documentNumber: params.id,
          type: {
            order_document_type_id: ORDER_DOCUMENT_TYPE.B2B
          },
          source: {
            order_document_source_id: ORDER_DOCUMENT_SOURCE.STER
          },
          file: {
            name: filename,
            base64: base64Content,
            extension
          }
        });
        const getNewDocuments = await libby.ster_order_document.getAllByOrderId(params.id);
        data.documents = getNewDocuments;
        setNewDocuments(getNewDocuments);
      } catch (e: any) {
        enqueueSnackbar(e.toString(), { variant: 'error' });
      }
    },
    [libby.ster_order_document]
  );

  const orderStateId = data?.state?.order_state_id;

  return (
    <>
      {validateAction(MENU_ACTIONS.EDIT_ORDER) &&
        (data.state.order_state_id === ORDER_STATE.OUT_FOR_DELIVERY || data.print ? (
          <Grid className={classes.EditButtonDiv} xs={12}>
            <Button disabled variant="contained" color="primary">
              {t('Edit')}
            </Button>
          </Grid>
        ) : (
          <Grid className={classes.EditButtonDiv} xs={12}>
            {loading && <CircularProgress className={classes.loading} color="primary" size={24} />}
            {verificatePathname[1] === 'orders' && !matchOrderState(orderStateId, [ORDER_STATE.BUDGET, ORDER_STATE.BUDGET_TO_BE_CONFIRMED]) && (
              <Button onClick={() => handleModal()} variant="contained" color="primary">
                {t('Edit')}
              </Button>
            )}
          </Grid>
        ))}

      <Grid className={classes.EditButtonDiv} xs={12}>
        {matchOrderState(orderStateId, [ORDER_STATE.DRAFT, ORDER_STATE.DRAFT_REVISION, ORDER_STATE.BUDGET_TO_BE_CONFIRMED]) && (
          <>
            {matchOrderState(orderStateId, [ORDER_STATE.DRAFT]) && validateAction(MENU_ACTIONS.EDIT_BUDGET) && (
              <>
                <Button variant="contained" color="primary" className={classes.Space} onClick={approveBudget} disabled={approving} style={{ width: '101.5px', height: '34.5' }}>
                  {!approving ? t('Approve') : <CircularProgress color="primary" style={{ width: '20px', height: '20px' }} />}
                </Button>

                <Button variant="contained" color="primary" onClick={() => onStateChange(ORDER_STATE.DRAFT_REVISION)} className={classes.Space}>
                  {t('Reject')}
                </Button>
              </>
            )}

            {(budgetEditPermission || validateAction(MENU_ACTIONS.EDIT_BUDGET)) && (
              <>
                {!matchOrderState(orderStateId, [ORDER_STATE.BUDGET_TO_BE_CONFIRMED]) && (
                  <>
                    <Button variant="contained" color="primary" onClick={() => onStateChange(ORDER_STATE.CANCELLED)} className={classes.Space}>
                      {t('Cancel')}
                    </Button>

                    <Button variant="contained" color="primary" onClick={goToCommentsBudget} className={classes.Space}>
                      {t('Comments')}
                    </Button>
                    <FileInput uploadFile={uploadFile} />

                    {verificatePathname[1] !== 'orders' && (
                      <Button variant="contained" color="primary" className={classes.Space} onClick={onAcceptBudget}>
                        {t('Accepted Budget')}
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Grid>

      <Grid className={classes.containInfo}>
        <Grid container spacing={1} justify="space-between" direction="row">
          <InformationSection title={t('Basic Information')} containerProps={gridBreakpointsOrderDetails} noPadding>
            {basicMapper({ ...data, order_retention_reason: retentionOrder, operation: { ...operationData } })}
          </InformationSection>
          {data.metadata?.discount && (
            <InformationSection title={t('Discount')} containerProps={gridBreakpointsOrderDetails} noPadding>
              {discountMapper(data.metadata?.discount)}
            </InformationSection>
          )}
          {data.metadata?.payment_method && (
            <InformationSection title={t('Payment method')} containerProps={gridBreakpointsOrderDetails} noPadding>
              {paymentMethodMapper(data.metadata?.payment_method)}
            </InformationSection>
          )}
          {data.buyer && (
            <InformationSection title={t('Buyer')} containerProps={gridBreakpointsOrderDetails} noPadding>
              {buyerMapper(data.buyer)}
            </InformationSection>
          )}
          {data.shipment && (
            <InformationSection title={t('Shipment')} containerProps={gridBreakpointsOrderDetails} noPadding>
              {shipmentMapper(data.shipment)}
            </InformationSection>
          )}
        </Grid>
      </Grid>
      <Grid className={classes.containInfo}>
        {data.items && (
          <InformationSection title={t('Items')} noPadding>
            <ItemsTable items={data.items} />
          </InformationSection>
        )}
      </Grid>
      <Grid className={classes.containInfo}>
        {data.documents && (
          <InformationSection title={t('Documents')} noPadding>
            <DocumentsTable documents={newDocuments || data.documents} />
          </InformationSection>
        )}
      </Grid>
    </>
  );
};

export const OrderDetailData = DatabaseConnector(OrderDetailDataRaw)('ster_order_table', 'ster_city', 'ster_state', 'ster_order_table_update', 'ster_order_document', 'ster_order_b2b', 'operationorder');
