import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from '../../../../../services/translation';
import { useBreadcrumbs, useFilerParamsId } from '../../../../../hooks';
import { RowsType } from '../../../../../utils/tables';
import CustomModal from '../../../../../services/customFormDialog';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import { ButtonDetailsType } from '../../../../../types/Button';
import { TitleBreadcrumbs } from '../../../../../interfaces';
import { makeFilter } from '../utils/makeFilter';
import { AddObjectivePeriodForm, CreateObjectivePeriodType } from '../components/AddObjectivePeriodForm';
import { EditObjectivePeriodForm } from '../components/EditObjectivePeriodForm';
import { formReportingObjectivePeriodInitialValues, validateReportingObjectivePeriodAdd } from '../utils/modelReportingObjectSettingAdd';
import { useObjectivePeriodsDAO, useObjectivePeriodsLibbyFetch } from 'business/objectives/ObjectivePeriods';
import { ObjectivePeriod } from '../../../../../interfaces/business/objectives/ObjectivePeriod';
import { useObjectivesFetchById } from 'business/objectives/Objective';
import { ObjectiveVal, ObjectiveValues } from 'interfaces/business';
import { useGroupLibbyFetch } from 'business/group/Group';
import { useObjectiveValDAO, useObjectiveValLibbyFetch } from 'business/objective/ObjectiveVal';
import { validateReportingObjectivePeriodEdit } from '../utils/modelReportingObjectivePeriodSettingEdit';
import { getWeeksInMonth } from '../../ReportingObjectSaleSettingList/utils/getWeeks';
import { MakeCell } from 'routes/components/MakeCell';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box } from '@material-ui/core';

const ConfirmModal = CustomModal(ConfirmDialog);
const AddObjectivePeriodModal = CustomModal(AddObjectivePeriodForm);
const EditObjectivePeriodModal = CustomModal(EditObjectivePeriodForm);

export const useReportingObjectSaleSettingPeriodsLogic = () => {
  const { objective_id } = useParams<{ objective_id: string }>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { data, working } = useObjectivesFetchById(objective_id);
  const { orderBy, direction, handleRequestSort, setFilter, paramsFetch } = useFilerParamsId({
    orderInit: 'objectiveValues.group_id',
    directionInit: 'asc',
    daoName: 'ster_objective_period',
    init: makeFilter({ objective_id })
  });
  const { data: dataGroup } = useGroupLibbyFetch();
  const { data: allDataPeriods, addCreate, reFetch } = useObjectivePeriodsLibbyFetch(paramsFetch);
  const { removeData, updateData } = useObjectiveValLibbyFetch();
  const objectivePeriodDAO = useObjectivePeriodsDAO();
  const objectiveValuesDAO = useObjectiveValDAO();

  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const title = useBreadcrumbs(`Goal period ${objective_id}`);
  const titleShow = useBreadcrumbs('Goal period', state && state?.goBack?.length ? state.goBack : title, objective_id);

  const handleOnOpenObjectivePeriod = useCallback(async () => {
    try {
      const objectiveDataForm = (await AddObjectivePeriodModal.show({
        confirmText: t('Add'),
        cancelText: t('Cancel'),
        title: t('Add goal period'),
        validate: validateReportingObjectivePeriodAdd,
        formInitialValues: formReportingObjectivePeriodInitialValues,
        objectives: allDataPeriods
      })) as CreateObjectivePeriodType;

      const weeks = getWeeksInMonth(objectiveDataForm.year, objectiveDataForm.month);
      const totalQuantity = objectiveDataForm.quantity;
      const totalAmount = objectiveDataForm.amount;
      const totalDaysInMonth = weeks.reduce((sum, week) => sum + week.dates.length, 0);
      const dailyQuantity = totalQuantity / totalDaysInMonth;
      const dailyAmount = totalAmount / totalDaysInMonth;

      const objectiveValues: Partial<ObjectiveValues>[] = weeks.map((week, index) => {
        const weekDays = week.dates.length;
        const weekQuantity = Math.floor(dailyQuantity * weekDays);
        const weekAmount = parseFloat((dailyAmount * weekDays).toFixed(2));
        return {
          week: index + 1,
          group_id: objectiveDataForm.group_id,
          quantity: weekQuantity,
          amount: weekAmount
        };
      });

      const totalRoundedQuantity = objectiveValues.reduce((sum, obj) => sum + Number(obj.quantity ?? 0), 0);
      const totalRoundedAmount = objectiveValues.reduce((sum, obj) => sum + Number(obj.amount ?? 0), 0);

      const quantityDifference = Number(objectiveDataForm.quantity) - totalRoundedQuantity;
      const amountDifference = Number(objectiveDataForm.amount) - totalRoundedAmount;

      if (quantityDifference !== 0 || amountDifference !== 0) {
        const lastEntry = objectiveValues[objectiveValues.length - 1];
        if (lastEntry) {
          lastEntry.quantity = (lastEntry.quantity ?? 0) + quantityDifference;
          lastEntry.amount = parseFloat(((lastEntry.amount ?? 0) + amountDifference).toFixed(2));
        }
      }
      if (objectiveDataForm) {
        const newObjective = await objectivePeriodDAO.save({
          objective_id: { objective_id },
          year: objectiveDataForm.year,
          month: objectiveDataForm.month,
          objectiveValues: objectiveValues
        });
        addCreate(newObjective);
        reFetch();
        enqueueSnackbar(t('Objective period succesfully add'), { variant: 'success' });
      }
    } catch (error) {
      if (error) {
        enqueueSnackbar(`${t('Failed to create')}: ${error}`, {
          variant: 'error'
        });
      }
    }
  }, [t, objective_id, enqueueSnackbar, addCreate, objectivePeriodDAO, reFetch, allDataPeriods]);

  const handleEditObjectivePeriod = useCallback(
    async (objectiveValues: ObjectiveVal) => {
      try {
        const objectiveDataForm = (await EditObjectivePeriodModal.show({
          confirmText: t('Update'),
          cancelText: t('Cancel'),
          title: t('Update goal period'),
          validate: validateReportingObjectivePeriodEdit,
          formInitialValues: {
            quantity: objectiveValues.quantity,
            amount: objectiveValues.amount
          }
        })) as CreateObjectivePeriodType;
        if (objectiveDataForm) {
          const editObjective = await objectiveValuesDAO.save({
            ...objectiveValues,
            quantity: objectiveDataForm.quantity,
            amount: objectiveDataForm.amount
          });
          updateData(editObjective, 'objective_period_id');
          enqueueSnackbar(t('Quantity and amount updated'), { variant: 'success' });
          reFetch();
        }
      } catch (error) {
        if (error) {
          enqueueSnackbar(`${t('Failed to update')}: ${error}`, {
            variant: 'error'
          });
        }
      }
    },
    [enqueueSnackbar, updateData, objectiveValuesDAO, t, reFetch]
  );

  const buttonDetails = useMemo((): ButtonDetailsType[] => {
    return [
      {
        id: 'printDispatchNote',
        onClick: () => handleOnOpenObjectivePeriod(),
        title: 'Add',
        type: 'submit',
        variant: 'contained'
      }
    ];
  }, [handleOnOpenObjectivePeriod]);

  const handleCancelObjectivePeriod = useCallback(
    async (objectiveValues: ObjectiveVal) => {
      try {
        const cancel = await ConfirmModal.show({
          title: t('Delete goal period'),
          content: `${t('You are about to remove a period from an objective, do you want to continue?')}`,
          confirmText: t('Confirm'),
          cancelText: t('Cancel')
        });
        if (cancel) {
          await objectiveValuesDAO.remove({
            ...objectiveValues
          });
          removeData(objectiveValues.objective_period_id, 'objective_period_id');
          reFetch();
          enqueueSnackbar(t('Objective period successfully removed'), { variant: 'success' });
        }
      } catch (error) {
        if (error) {
          enqueueSnackbar(`${t('Failed to delete')}: ${error}`, {
            variant: 'error'
          });
        }
      }
    },
    [enqueueSnackbar, objectiveValuesDAO, removeData, t, reFetch]
  );

  const rowsObjectivePeriods: RowsType[] = useMemo(() => {
    const dataPeriods = allDataPeriods as ObjectivePeriod[];
    if (!dataPeriods || dataPeriods.length === 0) {
      return [];
    }

    const lastObjective = dataPeriods.reduce((prev, current) => {
      return Number(current.objective_period_id) > Number(prev.objective_period_id) ? current : prev;
    }, dataPeriods[0]);

    if (!lastObjective) return [];
    const details = lastObjective?.objectiveValues.map((objective: ObjectiveValues) => {
      const selectedGroup = dataGroup?.find((group) => String(group.group_id) === String(objective.group_id));
      return {
        group_id: selectedGroup?.name,
        quantity: objective.quantity,
        amount: objective.amount,
        actions: (
          <Box display="flex" justifyContent="center">
            <MakeCell label="" icon={EditIcon} onClick={() => handleEditObjectivePeriod(objective)} />
            <MakeCell label="" icon={DeleteIcon} onClick={() => handleCancelObjectivePeriod(objective)} />
          </Box>
        )
      };
    });
    const totalQuantity = lastObjective.objectiveValues.reduce((acc, period) => acc + period.quantity, 0);
    const totalAmount = lastObjective.objectiveValues.reduce((acc, period) => {
      const amount = typeof period.amount === 'string' ? parseFloat(period.amount) : period.amount;
      return acc + amount;
    }, 0);

    const monthYear = `${String(lastObjective.month).padStart(2, '0')}-${lastObjective.year}`;

    return [
      {
        id: lastObjective.objective_period_id,
        month_year: monthYear,
        quantity: totalQuantity,
        amount: totalAmount.toFixed(2),
        details
      }
    ];
  }, [allDataPeriods, dataGroup, handleCancelObjectivePeriod, handleEditObjectivePeriod]);

  const columnsObjectivesPeriods = [t('Objective period id'), t('Month & year'), t('Total quantity'), t('Total amount')];

  return { data, working, titleShow, rows: rowsObjectivePeriods, buttonDetails, columns: columnsObjectivesPeriods, orderBy, direction, handleRequestSort, setFilter };
};
