import { Objectives } from '../../interfaces/business';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_objective';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Objectives>(DAO_NAME);

export const useObjectivesFetchById = fetchById;
export const useObjectivesLibbyCall = libbyCall;
export const useObjectivesDAO = libbyDAO;
export const useObjectivesLibbyFetch = libbyFetch;
