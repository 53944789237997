import { useCallback, useMemo, MouseEvent } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { orderBy as orderByLodash } from 'lodash';
import { useTranslation } from '../../../../../services/translation';
import { useBreadcrumbs, useCheckAll, useFilerParamsId } from '../../../../../hooks';
import { GroupProduct } from '../../../../../interfaces/business';
import { TitleBreadcrumbs } from '../../../../../interfaces';
import { ButtonDetailsType } from '../../../../../types/Button';
import { useGroupProductLibbyCall } from 'business/group/GroupsProduct';
import { Column } from '../../../../components/InfoTable';
import { useProductsInsertDAO } from 'business/ProductsInsert';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import CustomModal from '../../../../../services/customFormDialog';

const ConfirmModal = CustomModal(ConfirmDialog);
export const useReportingGroupProductSaleSettingLogic = () => {
  const match = useRouteMatch();
  const { group_id } = useParams<{ group_id: string }>();
  const {
    data: allProducts,
    recall,
    working: workingFields
  } = useGroupProductLibbyCall({
    methodName: 'getAll',
    params: [{ group_id }]
  });

  const groupProductsDAO = useProductsInsertDAO();
  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const selectTitle = t('Products');
  const title = useBreadcrumbs(selectTitle);
  const titleShow = useBreadcrumbs(selectTitle, state && state?.goBack?.length ? state.goBack : title);

  const { orderBy, direction, handleRequestSort, setFilter, filter } = useFilerParamsId({
    orderInit: 'name',
    daoName: 'groups_product'
  });
  const { checked, columnAll, handleCheckId, resetCheck } = useCheckAll(allProducts as GroupProduct, 'sku');

  const addItemSelect = useCallback(async () => {
    try {
      const cancel = await ConfirmModal.show({
        title: t('Delete product'),
        content: t('You are about to remove the selected products from this group. Do you want to continue?'),
        confirmText: t('Confirm'),
        cancelText: t('Cancel')
      });
      if (cancel) {
        const skuList = checked.all.map((item) => item.sku);
        await groupProductsDAO.remove({
          skuList,
          group_id: group_id
        });
        enqueueSnackbar(t('Successfully removed selected products'), { variant: 'success' });
        resetCheck();
        recall();
      }
    } catch {
      enqueueSnackbar(t('Failed to remove selected products'), { variant: 'error' });
    }
  }, [enqueueSnackbar, t, checked, groupProductsDAO, resetCheck, recall, group_id]);

  const rowsFields = useMemo(() => {
    const dataProducts = allProducts as GroupProduct[] | undefined;
    const parsedGroupId = parseInt(group_id, 10);
    const valueSearch = filter.search?.[0]?.value?.toLowerCase();

    const filteredProducts = dataProducts?.filter((product) => {
      const matchesGroupId = Number(product.group_id) === parsedGroupId;
      const matchesSearch = valueSearch ? product.name.toLowerCase().includes(valueSearch) || product.sku.toLowerCase().includes(valueSearch) : true;
      return matchesGroupId && matchesSearch;
    });
    if (!dataProducts) {
      return [];
    }
    const rows = filteredProducts
      ? filteredProducts?.map((product: GroupProduct) => ({
          id: product.group_id,
          select_order_id: handleCheckId(product.sku),
          name: product.name,
          sku: product.sku
        }))
      : [];
    return orderByLodash(rows, [orderBy], [direction]);
  }, [handleCheckId, allProducts, group_id, filter.search, direction, orderBy]);

  const buttonDetails = useMemo((): ButtonDetailsType[] => {
    return [
      {
        id: 'addProduct',
        onClick: (e: MouseEvent) => {
          e.stopPropagation();
          history.push({
            pathname: `${match.url}/add`,
            state: {
              goBack: titleShow
            }
          });
        },
        title: t('Add product'),
        variant: 'contained'
      },
      {
        id: 'addFieldGoalSales',
        onClick: addItemSelect,
        title: 'Delete',
        type: 'submit',
        variant: 'contained',
        disabled: !checked.all.length
      }
    ];
  }, [t, history, addItemSelect, checked, match.url, titleShow]);

  const columns: Array<Column> = [
    columnAll,
    {
      id: 'actions',
      label: '',
      width: 30
    },
    {
      id: 'name',
      label: t('Name')
    },
    {
      id: 'sku',
      label: 'Sku'
    }
  ];
  return { buttonDetails, selectTitle, rowsProducts: rowsFields, fetchMore: () => {}, working: workingFields, columns, checked, resetCheck, addItemSelect, orderBy, direction, handleRequestSort, setFilter, filter, titleShow };
};
