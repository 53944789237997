import { makeValidator, ValidatorRule, ValidatorType } from '../../../../../utils';

export const formReportingObjectiveInitialValues = {
  account_id: '',
  year: '',
  month: '',
  groups: []
};

const validations: ValidatorRule[] = [
  { key: 'account_id', validators: [ValidatorType.REQUIRED] },
  { key: 'year', validators: [ValidatorType.REQUIRED] },
  { key: 'month', validators: [ValidatorType.REQUIRED] },
  { key: 'groups', validators: [ValidatorType.REQUIRED] }
];

export const validateReportingObjectiveAdd = makeValidator(validations);
