import { useCallback } from 'react';
import { useField, useForm } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';
import { FormProps } from '../../../../../types';
import { useTranslation } from '../../../../../services/translation';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../../../utils';
import { ResetForm } from '../../../../../components/ResetForm';
import { useModalFormStyles } from '../../../../../theme';
import { Group } from 'interfaces/business';

export type CreateGroupType = {
  name: string;
};

export interface AddGroupDataForm extends FormProps {
  groups?: Group[];
}

export const AddGroupForm = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {},
  groups
}: AddGroupDataForm) => {
  const { t } = useTranslation();
  const styles = useModalFormStyles();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const onSubmit = useCallback(
    async ({ name }) => {
      onConfirm({
        name
      });
    },
    [onConfirm]
  );

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate: (values: any) => {
      const validationErrors = validate && validate(values);
      const manualValidationErrors: any = {};

      const groupExists = groups?.some((group: Group) => group.name.toLowerCase() === values.name.toLowerCase());

      if (groupExists) {
        manualValidationErrors.name = t('El nombre del grupo ya existe.');
      }
      return { ...validationErrors, ...manualValidationErrors };
    }
  });

  const name = useField('name', form);

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <DialogContent className={styles.title}>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          <TextField
            {...textFieldProps(`${t('Name')}`)}
            onChange={name.input.onChange}
            value={name.input.value}
            variant="standard"
            multiline
            error={Boolean(name.meta.touched && name.meta.error)}
            helperText={name.meta.touched && name.meta.error ? name.meta.error : ''}
            InputLabelProps={{
              shrink: true,
              ...getErrorAndHelperText(name)
            }}
          />

          {submitError && (
            <Typography style={{ display: 'block' }} variant="overline" color="error">
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ResetForm form={form} open={open} />
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            {cancelText}
          </Button>
          <Button disabled={name.input.value <= 0} color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
