import { ProductsInsert } from '../interfaces/business';
import { makeBusinessEntity } from '../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'products_insert';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<ProductsInsert>(DAO_NAME);

// hooks
export const useProductsInsertFetchById = fetchById;
export const useProductsInsertLibbyCall = libbyCall;
export const useProductsInsertDAO = libbyDAO;
export const useProductsInsertLibbyFetch = libbyFetch;
