import { Filter } from '../../../../../types';

type MakeFilterProps = {
  search?: string;
  monthYear: number;
  quantity: number;
  amount: number;
};

export const makeFilter = ({ search }: MakeFilterProps) => {
  const filter: Filter = {};
  if (search) {
    const searchArray = search.split(' ').reduce((accumulator: Filter, item, index) => {
      accumulator[index] = [
        {
          path: 'name',
          value: search,
          method: 'includes'
        }
      ];
      const numberSearch = parseInt(item, 10);
      if (numberSearch) {
        accumulator[index].push({
          path: 'objective_id',
          value: numberSearch
        });
      }
      return accumulator;
    }, {});
    filter.search = [...searchArray[0]];
  }

  return filter;
};
