import { useCallback, useMemo, MouseEvent } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from '../../../../../services/translation';
import { useBreadcrumbs, useFilerParamsId } from '../../../../../hooks';
import { RowsType } from '../../../../../utils/tables';
import CustomModal from '../../../../../services/customFormDialog';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import { ButtonDetailsType } from '../../../../../types/Button';
import { TitleBreadcrumbs } from '../../../../../interfaces';
import { useGroupDAO, useGroupLibbyFetch } from 'business/group/Group';
import { Group } from '../../../../../interfaces/business';
import { Column } from '../../../../components/InfoTable';
import { MenuActions, MenuItemComponentType } from '../../../../../components/MenuActions';
import { AddGroupForm, CreateGroupType } from '../components/AddGroupForm';
import { formReportingGroupInitialValues, validateReportingGroupAdd } from '../utils/modelReportingGroupAdd';

const AddGroupModal = CustomModal(AddGroupForm);
const ConfirmModal = CustomModal(ConfirmDialog);

export const useReportingGroupSaleSettingLogic = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const match = useRouteMatch();

  const { direction, paramsFetch, handleRequestSort, setFilter, filter } = useFilerParamsId({
    orderInit: 'name',
    daoName: 'ster_group',
    directionInit: 'asc'
  });

  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const { data: allGroups, working, addCreate, fetchMore, removeData, updateData } = useGroupLibbyFetch(paramsFetch);
  const groupDAO = useGroupDAO();
  const title = useBreadcrumbs('Groups');
  const titleShow = useBreadcrumbs('Groups', state && state?.goBack?.length ? state.goBack : title);

  const handleOnOpenAddGroup = useCallback(
    async (group?: Group) => {
      try {
        const groupDataForm = (await AddGroupModal.show({
          confirmText: t(group ? 'Update' : 'Add'),
          cancelText: t('Cancel'),
          title: t(group ? 'Edit group' : 'Add group'),
          validate: validateReportingGroupAdd,
          formInitialValues: group ? { name: group.name } : formReportingGroupInitialValues,
          groups: allGroups || []
        })) as CreateGroupType;
        if (groupDataForm) {
          const newGroup = await groupDAO.save({
            ...group,
            name: groupDataForm.name
          });
          const message = group ? t('Modified') : t('Aggregated');
          if (!group) {
            addCreate(newGroup);
          } else {
            updateData(newGroup, 'group_id');
          }
          enqueueSnackbar(`${t(`Group successfully ${message}`)}`, { variant: 'success' });
        }
      } catch {}
    },
    [allGroups, addCreate, groupDAO, enqueueSnackbar, t, updateData]
  );

  const buttonDetails = useMemo((): ButtonDetailsType[] => {
    return [
      {
        id: 'printDispatchNote',
        onClick: () => handleOnOpenAddGroup(),
        title: 'Add group',
        type: 'submit',
        variant: 'contained'
      }
    ];
  }, [handleOnOpenAddGroup]);

  const handleCancelGroup = useCallback(
    async (group: Group) => {
      try {
        const cancel = await ConfirmModal.show({
          title: t('Delete group'),
          content: `${t('You are about to remove the group $$$$ from the groups, do you want to continue?')}`.replace('$$$$', String(group.name)),
          confirmText: t('Confirm'),
          cancelText: t('Cancel')
        });
        if (cancel) {
          await groupDAO.remove({
            ...group
          });
          removeData(group.group_id, 'group_id');
          enqueueSnackbar(t('The group $$$$ deleted successfully').replace('$$$$', String(group.name)), { variant: 'success' });
        }
      } catch (error) {
        if (error) {
          enqueueSnackbar(t('Error deleting a group with products added and/or assigned to a target. Try deleting the products first and/or the target group'), {
            variant: 'info'
          });
        }
      }
    },
    [enqueueSnackbar, groupDAO, removeData, t]
  );

  const menuArray = useCallback(
    (group: Group): MenuItemComponentType[] => {
      const menu: MenuItemComponentType[] = [
        {
          title: 'Edit',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            handleOnOpenAddGroup(group);
          },
          disabled: false
        },
        {
          title: 'Products',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            history.push({
              pathname: `${match.path}/products/${group.group_id}`,
              state: {
                goBack: titleShow
              }
            });
          },
          disabled: false
        },
        {
          title: 'Delete',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            handleCancelGroup(group);
          },
          disabled: false,
          color: 'error'
        }
      ];
      return menu.filter((menuItems) => menuItems.disabled === false);
    },
    [match.path, history, titleShow, handleCancelGroup, handleOnOpenAddGroup]
  );

  const rowsGroup: RowsType[] = useMemo(() => {
    const dataGroup = allGroups as Group[];
    return dataGroup.map((group: Group) => ({
      id: group.group_id,
      name: group.name,
      groupProducts: group.groupProducts.length,
      actions: <MenuActions menuArray={menuArray(group)} />
    }));
  }, [allGroups, menuArray]);

  const columns: Array<Column> = [
    {
      id: 'actions',
      label: '',
      noSort: true,
      width: 30
    },
    {
      id: 'name',
      label: t('Name')
    },
    {
      id: 'groupProducts',
      label: t('Number of products'),
      noSort: true
    }
  ];

  return { working, titleShow, buttonDetails, rowsGroup, columns, setFilter, filter, handleRequestSort, direction, fetchMore };
};
