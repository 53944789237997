import { GroupProduct } from '../../interfaces/business';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'groups_product';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<GroupProduct>(DAO_NAME);

// hooks
export const useGroupProductFetchById = fetchById;
export const useGroupProductLibbyCall = libbyCall;
export const useGroupProductDAO = libbyDAO;
export const useGroupProductLibbyFetch = libbyFetch;
