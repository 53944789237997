import { useCallback, useMemo, MouseEvent } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from '../../../../../services/translation';
import { useBreadcrumbs, useFilerParamsId } from '../../../../../hooks';
import { Objective } from '../../../../../interfaces/business';
import { RowsType } from '../../../../../utils/tables';
import CustomModal from '../../../../../services/customFormDialog';
import { orderBy as orderByLodash } from 'lodash';
import { formReportingObjectiveInitialValues, validateReportingObjectiveAdd } from '../utils/modelReportingObjectSettingAdd';
import { MenuActions, MenuItemComponentType } from '../../../../../components/MenuActions';
import { AddObjectiveForm, CreateObjectiveType } from '../components/AddObjectiveForm';
import { ButtonDetailsType } from '../../../../../types/Button';
import { TitleBreadcrumbs } from '../../../../../interfaces';
import { useObjectiveLibbyFetch } from 'business/objective/Objective';
import { useAccountTargetlessLibbyCall } from 'business/account/AccountTargetless';
import { useObjectivesDAO } from 'business/objectives/Objective';
import { Objectives } from 'interfaces/business/objectives';
const AddObjectiveModal = CustomModal(AddObjectiveForm);
import { AccountTargetless } from 'interfaces/business/account';
import { ObjectiveValues } from 'interfaces/business/objectives';
import { getWeeksInMonth } from '../utils/getWeeks';

export const useReportingObjectivSaleSettingLogic = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const match = useRouteMatch();

  const { orderBy, direction, paramsFetch, handleRequestSort, setFilter, filter } = useFilerParamsId({
    orderInit: 'month_year',
    daoName: 'objective_offline'
  });

  const { data: allData, working, fetchMore, addCreate, reFetch } = useObjectiveLibbyFetch(paramsFetch);
  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const title = useBreadcrumbs('Settings sales by goals offline');
  const titleShow = useBreadcrumbs('Settings sales by goals offline', state && state?.goBack?.length ? state.goBack : title);
  const objectiveDAO = useObjectivesDAO();

  const { data: dataAll } = useAccountTargetlessLibbyCall({
    methodName: 'getAll'
  }) as { data: AccountTargetless[] };

  const handleOnOpenAddObjective = useCallback(async () => {
    try {
      const objectiveDataForm = (await AddObjectiveModal.show({
        confirmText: t('Add'),
        cancelText: t('Cancel'),
        title: t('Add objective'),
        validate: validateReportingObjectiveAdd,
        formInitialValues: formReportingObjectiveInitialValues,
        objectives: allData
      })) as CreateObjectiveType;
      const selectedUser = dataAll?.find((user) => Number(user.account_id) === Number(objectiveDataForm.account_id));

      if (!selectedUser) {
        return;
      }
      const fullName = selectedUser.full_name;

      const weeks = getWeeksInMonth(objectiveDataForm.year, objectiveDataForm.month);

      const objectiveValues: Partial<ObjectiveValues>[] = (objectiveDataForm.groups || []).flatMap((group) => {
        const totalDaysInMonth = weeks.reduce((sum, week) => sum + week.dates.length, 0);
        const dailyQuantity = group.quantity / totalDaysInMonth;
        const dailyAmount = group.amount / totalDaysInMonth;

        const groupObjectivePeriod = weeks.map((week, index) => {
          const weekDays = week.dates.length;
          const weekQuantity = Math.floor(dailyQuantity * weekDays);
          const weekAmount = parseFloat((dailyAmount * weekDays).toFixed(2));

          return {
            week: index + 1,
            group_id: group.group_name,
            quantity: weekQuantity,
            amount: weekAmount
          };
        });

        const totalRoundedQuantity = groupObjectivePeriod.reduce((sum, obj) => sum + Number(obj.quantity ?? 0), 0);
        const totalRoundedAmount = groupObjectivePeriod.reduce((sum, obj) => sum + Number(obj.amount ?? 0), 0);

        const quantityDifference = Number(group.quantity) - totalRoundedQuantity;
        const amountDifference = Number(group.amount) - totalRoundedAmount;

        if (quantityDifference !== 0 || amountDifference !== 0) {
          const lastEntry = groupObjectivePeriod[groupObjectivePeriod.length - 1];
          if (lastEntry) {
            lastEntry.quantity = (lastEntry.quantity ?? 0) + quantityDifference;
            lastEntry.amount = parseFloat(((lastEntry.amount ?? 0) + amountDifference).toFixed(2));
          }
        }

        return groupObjectivePeriod;
      });

      if (objectiveDataForm) {
        const newObjective: Objectives = await objectiveDAO.save({
          user_id: objectiveDataForm.account_id,
          name: fullName,
          objectivePeriod: [
            {
              year: objectiveDataForm.year,
              month: objectiveDataForm.month,
              objectiveValues: objectiveValues
            }
          ]
        });
        addCreate(newObjective);
        reFetch();
        enqueueSnackbar(`${t('Objective $$$$ successfully added}').replace('$$$$', newObjective.objective_id)}`, { variant: 'success' });
      }
    } catch (error) {
      if (error) {
        enqueueSnackbar(`${t('Failed to create')}: ${error}`, {
          variant: 'error'
        });
      }
    }
  }, [t, enqueueSnackbar, addCreate, objectiveDAO, dataAll, allData, reFetch]);

  const menuArray = useCallback(
    (dataObjective: Objective): MenuItemComponentType[] => {
      const menu: MenuItemComponentType[] = [
        {
          title: t('Period'),
          onClick: (e: MouseEvent) => {
            e.stopPropagation();

            history.push({
              pathname: `${match.path}/period/${dataObjective.objective_id}`,
              state: {
                goBack: titleShow
              }
            });
          },
          disabled: false
        }
      ];
      return menu.filter((menuItems) => menuItems.disabled === false);
    },
    [match.path, history, titleShow, t]
  );

  const rowsObjective: RowsType[] = useMemo(() => {
    const dataOrder = allData as Objective[];
    const { search } = filter;
    const valueSearch = search?.[0].value;
    const matchesSearch = dataOrder?.length && valueSearch ? dataOrder?.filter((rowsField) => rowsField?.name?.toLowerCase().includes(valueSearch.toLowerCase())) : dataOrder;
    const rows = matchesSearch.length
      ? matchesSearch.map((objective: Objective) => ({
          id: objective.objective_id,
          name: objective.name,
          month_year: objective.month_year,
          total_quantity: objective.total_quantity,
          total_amount: objective.total_amount,
          actions: <MenuActions menuArray={menuArray(objective)} />
        }))
      : [];
    return orderByLodash(rows, [orderBy], [direction]);
  }, [allData, menuArray, direction, orderBy, filter]);

  const buttons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'addGroup',
        onClick: (e: MouseEvent) => {
          e.stopPropagation();
          history.push({
            pathname: `${match.path}/groups`,
            state: {
              goBack: titleShow
            }
          });
        },
        title: t('Add group'),
        variant: 'contained'
      },
      {
        id: 'addGoal',
        onClick: () => handleOnOpenAddObjective(),
        title: t('Add goal'),
        variant: 'contained'
      }
    ],
    [handleOnOpenAddObjective, history, titleShow, match.path, t]
  );

  return {
    working,
    direction,
    orderBy,
    handleRequestSort,
    setFilter,
    filter,
    rowsObjective,
    fetchMore,
    buttons,
    titleShow
  };
};
