import { Grid } from '@material-ui/core';
import { ButtonComponent } from '../../../../components/Button';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { useTranslation } from '../../../../services/translation';
import { ButtonDetailsType } from '../../../../types/Button';
import InfoTable from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';
import { SearchFilters } from './components/SearchFilters';
import { useReportingObjectivSaleSettingLogic } from './hook/useReportingObjectSalesSettingList';
import { columnsReportingObjectSaleSetting } from './utils/columnsReportingObjectivSaleSetting';

export const ReportingObjectiveSalesSettingList = () => {
  const { t } = useTranslation();

  const { rowsObjective, working, handleRequestSort, direction, fetchMore, buttons, filter, setFilter, titleShow, orderBy } = useReportingObjectivSaleSettingLogic();

  return (
    <ScreenAligned
      title={titleShow}
      additionalTitle={
        <>
          {buttons.map((dataButton: ButtonDetailsType) => (
            <ButtonComponent
              key={dataButton.id}
              title={dataButton.title}
              className={dataButton.className}
              variant={dataButton.variant}
              color={dataButton.color}
              onClick={dataButton.onClick}
              type={dataButton.type}
              disabled={dataButton.disabled}
              loading={dataButton.loading}
            />
          ))}
        </>
      }
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <SearchFilters initFetch={filter} onFilterChange={setFilter} />
        <InfoTable
          columns={columnsReportingObjectSaleSetting}
          rows={rowsObjective}
          onBottomScroll={fetchMore}
          onSortChange={handleRequestSort}
          direction={direction}
          rowIdKey="objective_id"
          md={12}
          sm={12}
          xs={12}
          orderBy={orderBy}
        />
        {(working || !!rowsObjective.length) && <LoadingData label={`${t('Loaded goals')}`} working={working} cant={rowsObjective.length} />}
      </Grid>
    </ScreenAligned>
  );
};
