import React, { useCallback, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { useField, useForm } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { FormProps } from '../../../../../types';
import { DateClose } from '../../../../../components/DateClose';
import { useTranslation, useLanguage } from '../../../../../services/translation';
import { formatDate, makeGetErrorAndHelperText, replaceNonNumericCharacters, textFieldProps } from '../../../../../utils';
import { ResetForm } from '../../../../../components/ResetForm';
import { useModalFormStyles } from '../../../../../theme';
import { SelectGoalType } from '../../../../../components/SelectGoalType';
import { SelectGoalTypeValue } from '../../../../../components/SelectGoalTypeValue';
import { SelectTrimester } from '../../../../../components/SelectTrimester';
import { GOAL_TYPE, GOAL_TYPE_VALUE, GOAL_RANGE_TYPE } from '../../../../../const';
import { SelectGoalRangeType } from '../../../../../components/SelectGoalRangeType';
import { SelectYearWeek } from '../../../../../components/SelectYearWeek';
import { SelectMarketplaceGoal } from '../../../../../components/SelectMarketplaceGoal';
import { SelectCategoryGoal } from '../../../../../components/SelectCategoryGoal';

type goalValues = {
  id: string;
  value: string;
};

export type CreateGoalType = {
  goal_range_type: string;
  value: number;
  goal_type: string;
  start_date: Moment;
  end_date: Moment;
  name: string;
  goal_type_value: string;
  goal_marketplaces: goalValues[];
  goal_categories: goalValues[];
};

export const AddGoalForm = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {}
}: FormProps) => {
  const { t } = useTranslation();
  const { getLanguage } = useLanguage();
  const language = getLanguage();
  const styles = useModalFormStyles();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const onSubmit = useCallback(
    async ({ goal_type, value, year, name, goal_type_value, trimester_date, goal_range_type, year_week, month_year, goal_marketplaces, goal_categories }) => {
      let start_date = '';
      let end_date = '';

      switch (goal_range_type) {
        case GOAL_RANGE_TYPE.QUARTERLY.toString(): {
          const yearDate = parseInt(moment(year).format('YYYY'), 10);
          start_date = formatDate(moment().quarter(trimester_date).year(yearDate).startOf('quarter').toDate(), true, false, 'YYYY-MM-DD HH:mm:ss');
          end_date = formatDate(moment().quarter(trimester_date).year(yearDate).endOf('quarter').toDate(), true, false, 'YYYY-MM-DD HH:mm:ss');
          break;
        }
        case GOAL_RANGE_TYPE.MONTHLY.toString(): {
          start_date = formatDate(moment(month_year).startOf('month').toDate(), true, false, 'YYYY-MM-DD HH:mm:ss');
          end_date = formatDate(moment(month_year).endOf('month').toDate(), true, false, 'YYYY-MM-DD HH:mm:ss');
          break;
        }
        case GOAL_RANGE_TYPE.WEEKLY.toString(): {
          start_date = moment(year_week.split('.')[0]).format('YYYY-MM-DD HH:mm:ss');
          end_date = moment(year_week.split('.')[1]).format('YYYY-MM-DD HH:mm:ss');
          break;
        }
        default: {
          break;
        }
      }
      onConfirm({
        end_date,
        start_date,
        goal_type,
        goal_type_value,
        value,
        name,
        goal_range_type,
        goal_marketplaces,
        goal_categories
      });
    },
    [onConfirm]
  );

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate: (values: any) => {
      const validationErrors = validate && validate(values);
      const manualValidationErrors: any = {};

      if (values.goal_range_type === GOAL_RANGE_TYPE.QUARTERLY.toString()) {
        if (!values.year) manualValidationErrors.year = t('Please fill out this field.');
        if (!values.trimester_date) manualValidationErrors.trimester_date = t('Please fill out this field.');
      } else if (values.goal_range_type === GOAL_RANGE_TYPE.MONTHLY.toString()) {
        if (!values.month_year) manualValidationErrors.month_year = t('Please fill out this field.');
      } else if (values.goal_range_type === GOAL_RANGE_TYPE.WEEKLY.toString()) {
        if (!values.year_week) manualValidationErrors.year_week = t('Please fill out this field.');
      }

      if (values.goal_type === GOAL_TYPE.PRODUCT.toString()) {
        if (!values.goal_categories) manualValidationErrors.goal_categories = t('Please fill out this field.');
      } else if (values.goal_type === GOAL_TYPE.MARKETPLACE.toString()) {
        if (!values.goal_marketplaces) manualValidationErrors.goal_marketplaces = t('Please fill out this field.');
      }

      return { ...(validationErrors || {}), ...manualValidationErrors };
    }
  });

  const name = useField('name', form);
  const valueGoal = useField('value', form);
  const year = useField('year', form);
  const trimester_date = useField('trimester_date', form);
  const goal_type = useField('goal_type', form);
  const goal_type_value = useField('goal_type_value', form);
  const goal_range_type = useField('goal_range_type', form);
  const year_week = useField('year_week', form);
  const month_year = useField('month_year', form);
  const goal_marketplaces = useField('goal_marketplaces', form);
  const goal_categories = useField('goal_categories', form);

  useEffect(() => {
    if (goal_type.input.value === GOAL_TYPE.PRODUCT.toString()) {
      form.change('goal_type_value', GOAL_TYPE_VALUE.QUANTITY.toString());
    } else if (goal_type.input.value === GOAL_TYPE.MARKETPLACE.toString()) {
      form.change('goal_type_value', GOAL_TYPE_VALUE.MONETARY.toString());
    }
  }, [form, goal_type_value.input.value, goal_type.input.value]);

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <DialogContent className={styles.title}>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          <TextField
            {...textFieldProps(`${t('Name')}`)}
            onChange={name.input.onChange}
            value={name.input.value}
            variant="standard"
            multiline
            InputLabelProps={{
              shrink: true,
              ...getErrorAndHelperText(name)
            }}
          />

          <SelectGoalType
            inputProps={{
              onChange: goal_type.input.onChange,
              value: goal_type.input.value || '',
              ...getErrorAndHelperText(goal_type)
            }}
            filterGoal={[GOAL_TYPE.MARKETPLACE, GOAL_TYPE.PRODUCT]}
          />

          <SelectGoalTypeValue
            disabled
            inputProps={{
              onChange: goal_type_value.input.onChange,
              value: goal_type_value.input.value || '',
              ...getErrorAndHelperText(goal_type_value)
            }}
          />

          <SelectGoalRangeType
            inputProps={{
              onChange: goal_range_type.input.onChange,
              value: goal_range_type.input.value || '',
              ...getErrorAndHelperText(goal_range_type)
            }}
            disabled
          />

          {goal_range_type.input.value === GOAL_RANGE_TYPE.WEEKLY.toString() && (
            <>
              <SelectYearWeek
                inputProps={{
                  onChange: year_week.input.onChange,
                  value: year_week.input.value || '',
                  ...getErrorAndHelperText(year_week)
                }}
              />
            </>
          )}

          {goal_range_type.input.value === GOAL_RANGE_TYPE.MONTHLY.toString() && (
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={language}>
              <DatePicker
                value={month_year.input.value ? moment(month_year.input.value) : null}
                autoOk
                onChange={month_year.input.onChange}
                views={['month', 'year']}
                fullWidth
                label={t('Month & year')}
                InputLabelProps={{
                  shrink: true
                }}
                {...getErrorAndHelperText(month_year)}
              />
            </MuiPickersUtilsProvider>
          )}

          {goal_range_type.input.value === GOAL_RANGE_TYPE.QUARTERLY.toString() && (
            <>
              <SelectTrimester
                inputProps={{
                  onChange: trimester_date.input.onChange,
                  value: trimester_date.input.value || '',
                  ...getErrorAndHelperText(trimester_date)
                }}
              />
              <DateClose views={['year']} name="Start Date" value={year.input.value ? moment(year.input.value).utc() : null} onChange={year.input.onChange} disableFuture={false} {...getErrorAndHelperText(year)} />
            </>
          )}

          {goal_type.input.value === GOAL_TYPE.MARKETPLACE.toString() && (
            <SelectMarketplaceGoal
              inputProps={{
                onChange: goal_marketplaces.input.onChange,
                value: goal_marketplaces.input.value || '',
                error: { ...getErrorAndHelperText(goal_marketplaces) }
              }}
              label={t('Marketplace')}
              from={month_year.input.value || null}
            />
          )}

          {goal_type.input.value === GOAL_TYPE.PRODUCT.toString() && (
            <SelectCategoryGoal
              inputProps={{
                onChange: goal_categories.input.onChange,
                value: goal_categories.input.value || '',
                error: { ...getErrorAndHelperText(goal_categories) }
              }}
              label={t('Categories')}
              from={month_year.input.value || null}
            />
          )}

          <TextField
            {...textFieldProps(t('Value'))}
            {...valueGoal.input}
            {...getErrorAndHelperText(valueGoal)}
            variant="standard"
            onChange={(event) => {
              const { value } = event.target;
              event.target.value = replaceNonNumericCharacters(value);
              valueGoal.input.onChange(event);
              return event;
            }}
            type="text"
          />

          {submitError && (
            <Typography style={{ display: 'block' }} variant="overline" color="error">
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ResetForm form={form} open={open} />
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            {cancelText}
          </Button>
          <Button color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
