import { Filter } from '../../../../../types/Filter';

type MakeFilterProps = {
  objective_id?: string;
};

export const makeFilter = ({ objective_id }: MakeFilterProps) => {
  const filter: Filter = {};

  if (objective_id) {
    filter.objective_id = [
      {
        path: 'objective_id.objective_id',
        value: objective_id
      }
    ];
  }

  return filter;
};
