import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';
import { Order_shipment } from '../../../interfaces/business';

const DAO_NAME = 'ster_order_shipment';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Order_shipment>(DAO_NAME);

// hooks
export const useOrderShipmentFetchById = fetchById;
export const useOrderShipmentLibbyCall = libbyCall;
export const useOrderShipmentDAO = libbyDAO;
export const useOrderShipmentLibbyFetch = libbyFetch;
