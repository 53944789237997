import { Grid } from '@material-ui/core';
import React from 'react';
import { ButtonComponent } from '../../../../components/Button';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { useTranslation } from '../../../../services/translation';
import InfoTable from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';
import { SearchFilters } from './components/SearchFilter';

import { useReportingGroupSaleSettingLogic } from './hooks/useReportingGroupSaleSetting';

export const ReportingGroupSaleSetting = () => {
  const { columns, rowsGroup, working, titleShow, buttonDetails, filter, setFilter, handleRequestSort, direction, fetchMore } = useReportingGroupSaleSettingLogic();
  const { t } = useTranslation();
  return (
    <ScreenAligned
      title={titleShow}
      additionalTitle={
        <>
          <Grid container>
            {buttonDetails.map((dataButton) => (
              <ButtonComponent
                key={dataButton.id}
                title={dataButton.title}
                className={dataButton.className}
                variant={dataButton.variant}
                color={dataButton.color}
                onClick={dataButton.onClick}
                type={dataButton.type}
                disabled={dataButton.disabled}
                loading={dataButton.loading}
              />
            ))}
          </Grid>
        </>
      }
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <SearchFilters filter={filter} onFilterChange={setFilter} />
        <InfoTable columns={columns} rows={rowsGroup} onBottomScroll={fetchMore} onSortChange={handleRequestSort} direction={direction} rowIdKey="group_id" md={12} sm={12} xs={12} />
        {working || (!!rowsGroup.length && <LoadingData label={`${t('Loaded goals')}`} working={working} cant={rowsGroup.length} />)}
      </Grid>
    </ScreenAligned>
  );
};
