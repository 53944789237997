import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class GroupDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_group', 'group_id');
  }

  getAllGroup() {
    return this.query().limit(0, 10000).run();
  }
}
